const protocol = process.env.REACT_APP_PROTOCOL;
const host = process.env.REACT_APP_HOST;
const port = process.env.REACT_APP_STRAPI_PORT;
const folder = process.env.REACT_APP_STRAPI_FOLDER;
let baseURL = `${protocol}://${host}`;
if (protocol === 'http' && port) {
  baseURL += `:${port}`;
}
if (folder) {
  baseURL += `/${folder}`;
}

const config = {
  http: {
    HOST: process.env.REACT_APP_HOST,
    STRAPI_PORT: process.env.REACT_APP_STRAPI_PORT,
    GOOGLE_EXPORT_PORT: process.env.REACT_APP_GOOGLE_EXPORT_PORT,
    BASE_EXPORT_URL: process.env.REACT_APP_BASE_EXPORT_URL,
    BASE_URL: baseURL,
  },
  auth: {
    GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  },
  TECH_SKILLS_TABS: {
    developmentTechnologies: process.env.REACT_APP_DEVELOPMENT_TECHNOLOGIES,
    ideOrTools: process.env.REACT_APP_IDE_TOOLS,
    programmingLanguages: process.env.REACT_APP_PROGRAMMING_LANGUAGES,
    versionControlSystems: process.env.REACT_APP_VERSION_CONTROL_SYSTEM,
    dataBases: process.env.REACT_APP_DATABASES,
    ciCdTools: process.env.REACT_APP_CI_CD_TOOLS,
    iOsTechnologies: process.env.REACT_APP_IOS_TECHNOLOGIES,
    androidTechnologies: process.env.REACT_APP_ANDROID_TECHNOLOGIES,
    developmentMethodologies: process.env.REACT_APP_DEVELOPMENT_METHODOLOGIES,
  },
  manager: {
    id: process.env.REACT_APP_ROLE_ID,
  },
  teamManager: {
    id: process.env.REACT_APP_TEAM_MANAGER_ROLE_ID,
  },
  saleRole: {
    id: process.env.REACT_APP_SALE_ROLE_ID,
  },
};

export default config;
