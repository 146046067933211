import config from '../config';
import { getElementFromStorage } from '../utils/storage';

export const putCVReport = (cv, messageText, senderID) => {
  const BaseURL = `${config.http.BASE_URL}/resume-items/sendForRevision`;
  const token = getElementFromStorage('jwtToken');
  const data = {message: messageText, resume: cv, sender: senderID};
  return fetch(BaseURL, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token === 'undefined' ? '' : token}`,
    },
    body: JSON.stringify(data),
  })
    .then(response => (response.json().then(json => json)
      .catch(() => ({
        error: true,
      }))), (error) => {
      throw new Error(error.message);
    });
};

export default putCVReport;
