import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import AppContext from '../../context/AppContext';
import Users from './tabs/Users';
import userIco from '../../assets/images/icons/user.png';

const RightSidebar = ({ lastCvUpdate }) => {
  const { t } = useTranslation('rightSidebar');

  const context = useContext(AppContext);
  const { dispatch } = context;

  const tabs = [
    {
      key: 'users',
      name: t('users.title'),
      icon: userIco, title: 'Summary'
    }
  ];
  const currentTab = tabs[0].key;

  const renderTabs = () => {
    switch (currentTab) {
      case tabs[0].key:
        return <Users dispatch={dispatch} lastCvUpdate={lastCvUpdate} />;
      default:
        return null;
    }
  };

  return (
    <div
      id="rightSidebar"
      className="z-10 py-6 h-screen bg-white shadow-2xl overflow-y-scroll overflow-x-hidden"
    >
      <div className="px-3 w-full">{renderTabs()}</div>
    </div>
  );
};

export default RightSidebar;
