import React, { createContext, useReducer } from 'react';
import get from 'lodash/get';
import set from 'lodash/set';
import remove from 'lodash/remove';
import isEqual from 'lodash/isEqual';

import demoData from '../assets/demo/data.json';
import { moveBy } from '../utils';
import { clearCvModel, isEqualProjects } from '../helpers/AppHelpers';

const initialState = {
  data: { ...clearCvModel },
  dataChanged: false,
  showSortPopup: false,
  theme: {
    layout: 'Gengar',
    font: {
      family: '',
    },
    colors: {
      background: '#ffffff',
      primary: '#212121',
      accent: '#0F46A0',
    },
  },
  settings: {
    language: 'en',
  },
  loadingSpinner: false,
  isAdmin: false,
  isSale: false,
  ownCvId: '',
  ownUserInfo: {},
  selectedCvId: '',
  selectedUserId: '',
};

let comparisonState;

const reducer = (state, { type, payload }) => {
  let items;
  const newState = JSON.parse(JSON.stringify(state));

  const setChangedState = () => {
    const changedState = set({ ...newState }, payload.key, payload.value);
    const areStatesEqual = isEqual(changedState.data, comparisonState.data);
    const result = set({ ...changedState }, 'dataChanged', !areStatesEqual);

    const comparisonProjects = comparisonState.data.projects.items;
    const changedProjects = changedState.data.projects.items;
    set(result, 'showSortPopup', !isEqualProjects(comparisonProjects, changedProjects));

    return result;
  };

  switch (type) {
    case 'migrate_section':
      return set({ ...newState }, `data.${payload.key}`, payload.value);
    case 'add_item':
      items = get({ ...newState }, `data.${payload.key}.items`, []);
      items.push(payload.value);
      return setChangedState();
    case 'add_item_unshift':
      items = get({ ...newState }, `data.${payload.key}.items`, []);
      items.unshift(payload.value);
      return setChangedState();
    case 'delete_item':
      items = get({ ...newState }, `data.${payload.key}.items`, []);
      remove(items, x => x.id === payload.value.id);
      return setChangedState();
    case 'move_item_up':
      items = get({ ...newState }, `data.${payload.key}.items`, []);
      moveBy(items, payload.value, -1);
      return setChangedState();
    case 'move_item_down':
      items = get({ ...newState }, `data.${payload.key}.items`, []);
      moveBy(items, payload.value, 1);
      return setChangedState();
    case 'move_item_by':
      items = get({ ...newState }, `data.${payload.key}.items`, []);
      moveBy(items, payload.value, payload.move);
      return setChangedState();
    case 'on_input':
      return setChangedState();
    case 'import_data':
      set(initialState, 'showSortPopup', false);
      if (payload === null) {
        comparisonState = { ...initialState };
        return initialState;
      }

      for (const section of Object.keys(initialState.data)) {
        if (!(section in payload.data)) {
          payload.data[section] = initialState.data[section];
        }
      }
      comparisonState = { ...payload };

      return {
        ...newState,
        ...payload,
        dataChanged: false,
      };
    case 'load_demo_data':
      return {
        ...newState,
        ...demoData,
      };
    case 'reset':
      return initialState;
    case 'isAdmin':
      return set({ ...newState }, 'isAdmin', payload);
    case 'isSale':
      return set({ ...newState }, 'isSale', payload);
    case 'change_spinner_value':
      return set({ ...newState }, 'loadingSpinner', payload);
    case 'setOwnCvId':
      return set({ ...newState }, 'ownCvId', payload);
    case 'setOwnUserInfo':
      return set({ ...newState }, 'ownUserInfo', payload);
    case 'setSelectedCvId':
      return set({ ...newState }, 'selectedCvId', payload);
    case 'setSelectedUserId':
      return set({ ...newState }, 'selectedUserId', payload);
    case 'setResumeItems':
      return set({ ...newState }, 'resumeItems', payload);
    case 'setActiveResume':
      return set({ ...newState }, 'activeResume', payload);
    default:
      return newState;
  }
};

const AppContext = createContext(initialState);
const { Provider } = AppContext;

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export const AppProvider = StateProvider;
export const AppConsumer = AppContext.Consumer;

export default AppContext;
