import React, { useContext, useEffect } from 'react';

import AppContext from '../../../context/AppContext';
import Checkbox from '../../../shared/Checkbox';
import Item from '../../../shared/KeySkills/Item';
import AddItem from '../../../shared/KeySkills/AddItem';
import { TabDescription } from '../../../shared/TabDescription/TabDescription';

const KeySkillsTab = ({ data, onChange }) => {
  const context = useContext(AppContext);
  const { dispatch } = context;

  useEffect(() => {
    if (!('keySkills' in data) || !data.keySkills) {
      dispatch({
        type: 'migrate_section',
        payload: {
          key: 'keySkills',
          value: {
            enable: true,
            heading: 'Key skills',
            items: [],
          },
        },
      });
    }
  }, [data, dispatch]);

  return (
    <>
      <div className="mb23 flex justify-between items-center">
        <div className="title_tab">KeySkills</div>

        <div className="col-span-1">
          <Checkbox
            checked={data.keySkills?.enable}
            onChange={v => onChange('data.keySkills.enable', v)}
          />
        </div>
      </div>

      <TabDescription tab={'keySkills'} />

      {data.keySkills?.items?.map((x, index) => (
        <Item
          item={x}
          key={x.id}
          index={index}
          dispatch={dispatch}
          first={index === 0}
          last={index === data.keySkills?.items.length - 1}
        />
      ))}

      <AddItem dispatch={dispatch} keySkills={data.keySkills?.items} />
    </>
  );
};

export default KeySkillsTab;
