import React, { useEffect, useState } from 'react';
import { Select, ConfigProvider } from 'antd';
import mainIco from '../../assets/images/icons/main_icon.png';
import { setSelectedCvId, importData, setActiveResume } from '../../utils';
import ChangesDialog from '../ChangesDialog';

export const HeaderSelect = (porop) => {
  const { Option } = Select;
  const { state, history, dispatch } = porop;
  const { resumeItems, ownCvId, selectedCvId, loadingSpinner, dataChanged, isSale, isTempLink } = state;

  const [dialogData, setDialogData] = useState({ isOpened: false });
  const [selectedCv, setSelectedCv] = useState({});
  useEffect(() => {
    setActiveResume(dispatch, selectedCv)
  }, [dispatch, selectedCv]);
  useEffect(() => {
    const selectedId = selectedCvId || ownCvId;
    setSelectedCv(selectedId);
  }, [loadingSpinner, setSelectedCv, selectedCvId, ownCvId]);

  const onChange = (value, prop) => {
    if (dataChanged && !isSale && !isTempLink) { setDialogData({ isOpened: true }) }
    else {
      history.push('/');
      setSelectedCv(value);
      if (selectedCvId) {
        setSelectedCvId(dispatch, prop.item.id);
      } else {
        setSelectedCvId(dispatch, prop.item.id);
      }

      importData(dispatch, { data: { ...prop.item } });
    }
  };
  return (
    <div style={{ height: '40px !important' }}>
      <ChangesDialog data={dialogData} setDialogData={setDialogData} />
      <ConfigProvider
        theme={{
          token: {
            // controlHeight: '40px',
          },
        }}
      >
        <Select
          className='cv-select'
          value={selectedCv}
          style={{ width: '200px', marginRight: '5px', display: 'flex', alignItems: 'center', height: '100%' }}
          onChange={onChange}
        >
          {resumeItems.map((item) => (
            <Option key={item.id} value={item.id.toString()} item={item}>
              <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                <div className="flex justify-center items-center">
                  {item.isMain ? <img src={mainIco} alt="" style={{ width: '30px', height: '30px' }} className="mr-2" /> : null}
                  <div
                    className={`${item.isMain ? '' : 'ml-2'} 
                    flex flex-col justify-center content-center`}
                  >
                    <div style={item.isMain ? { maxWidth: 130 } : { maxWidth: 152 }}>
                      <p className="truncate">{item.name}</p>
                    </div>
                  </div>
                </div>
              </div>
            </Option>
          ))}
        </Select>
      </ConfigProvider>
    </div>
  );
};
