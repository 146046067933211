import React from 'react';
import { useTranslation } from 'react-i18next';
import TextField from '../TextField';
import TextArea from '../TextArea';

const Form = ({ item, onChange, identifier = '', errors, setErrors }) => {
  const { t } = useTranslation(['leftSidebar', 'app']);

  return (
    <div>
      <TextField
        className="mb-6"
        label={t('education.key.label')}
        placeholder={t('education.key.placeholder')}
        value={item.key}
        star="true"
        validation={errors.key}
        onChange={v => {
          onChange(`${identifier}key`, v);
          setErrors({
            key: false,
            major: errors.major,
          });
        }}
        tooltip={'right'}
        tooltipType={'education'}
        tooltipLabel={t('education.key.label')}
      />

      <TextField
        className="mb-6"
        label={t('education.major.label')}
        placeholder={t('education.major.placeholder')}
        value={item.major}
        star="true"
        validation={errors.major}
        onChange={v => {
          onChange(`${identifier}major`, v);
          setErrors({
            key: errors.key,
            major: false,
          });
        }}
        tooltip={'right'}
        tooltipType={'education'}
        tooltipLabel={t('education.major.label')}
      />

      <TextArea
        rows="5"
        className="mb-6"
        label={t('education.description.label')}
        placeholder={t('education.description.placeholder')}
        value={item.description}
        onChange={v => onChange(`${identifier}description`, v)}
        tooltip={'right'}
        tooltipType={'education'}
        tooltipLabel={t('education.description.label')}
      />
    </div>
  );
};

export default Form;
