import config from '../config';

export const PostDataLogin = (data) => {
  const BaseURL = `${config.http.BASE_URL}/auth/google/callback/?stcsKeyEncrypted=${data}`;

  return fetch(BaseURL, { method: 'GET' })
    .then(response => (response.status !== 200 ? ({
      error: true,
    }) : response.json().then(json => json)
      .catch(() => ({
        error: true,
      }))), (error) => {
        throw new Error(error.message);
      });
};

export default PostDataLogin;
