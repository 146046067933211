import React, { useState, useEffect, useCallback } from 'react';
import { Space } from 'antd';
import { get, filter } from 'lodash';
import { companyName, searchParamList } from '../../../../helpers/AppHelpers';
import { GetSearchResultsByUsers } from '../../../../services/GetSearchResultsByUsers';
import { GetPositions } from '../../../../services/GetPositions';
import { getKeySkills } from '../../../../services/GetKeySkills';
import { GetContractTypes } from '../../../../services/GetContractTypes';
import OptionFilter from './filters/OptionFilter';
import UserNameFilter from './filters/UserNameFilter';
import ProjectFilter from './filters/ProjectFilter';
import ContractTypeFilter from './filters/ContractTypeFilter';
import TechSkillsFilter from './filters/TechSkillsFilter';
import KeySkillsFilter from './filters/KeySkillsFilter';
import IsFireFilter from './filters/IsFireFilter';
import EnglishFilter from './filters/EnglishFilter';
import PositionFilter from './filters/PositionFilter';
import config from '../../../../config';

export default function SearchBar({ ownUserInfo, setFilteredUserData, setLoadingSpinner }) {
  const [searchItems, setSearchItems] = useState([]);
  const [keySkillList, setKeySkillList] = useState([]);
  const [positionList, setPositionList] = useState([]);
  const [projects, setProjects] = useState([]);

  const [user, setUser] = useState({});
  const [usernames, setUserNames] = useState([]);
  const [project, setProject] = useState({});
  const [position, setPosition] = useState({});
  const [englishLevel, setEnglishLevel] = useState({});
  const [selectedKeySkills, setKeySkills] = useState([]);
  const [selectedTechSkills, setTechSkills] = useState([]);
  const [valueIsFired, setIsFired] = useState({});
  const [contractTypes, setContractTypes] = useState([]);
  const [contractType, setContractType] = useState({});

  useEffect(() => {
    (async () => {
      const response = await fetch(`${config.http.BASE_URL}/Autocomplete-project-fields`);
      let projectsResponse = await response.json();
      projectsResponse = projectsResponse.sort((a, b) =>
        get(a, 'title').localeCompare(get(b, 'title')),
      );
      setProjects(projectsResponse.map((item) => item.title));
    })();
  }, []);

  const filterByParameter = (data) => {
    data = filter(data, (item) => {
      return item && item.email.includes(companyName);
    });
    return data.sort((a, b) => get(a, 'username').localeCompare(get(b, 'username')));
  };

  const getPositionsData = useCallback(() => {
    (async () => {
      let positionsResp = await GetPositions();
      positionsResp = positionsResp.sort((a, b) => get(a, 'title').localeCompare(get(b, 'title')));
      const positions = positionsResp.map((item) => ({ value: item.title }));
      setPositionList(positions);
    })();
  }, [setPositionList]);

  const getKeySkillsData = useCallback(() => {
    (async () => {
      const keySkills = await getKeySkills();
      setKeySkillList(keySkills);
    })();
  }, [setKeySkillList]);

  const getContractTypesData = useCallback(() => {
    (async () => {
      const typesData = await GetContractTypes();
      const types = typesData.map((item) => {
        const title = item.title
          .replace(/_/g, ' ')
          .toLowerCase()
          .replace(/\b\w/g, (match) => match.toUpperCase());
        return { searchParam: 'contractType', value: item.title, label: title };
      });
      setContractTypes(types);
    })();
  }, [setContractTypes]);

  const getSearchResultsData = useCallback(
    (queryString) => {
      setLoadingSpinner(true);
      (async () => {
        try {
          if (queryString || !!!usernames.length) {
            const response = await GetSearchResultsByUsers(ownUserInfo, queryString);
            if (response.aborted) {
              return;
            }
            const cvsRes = filterByParameter(response.length ? response : []);
            setUserNames(cvsRes.map((item) => item.username));
            setFilteredUserData(cvsRes.length ? cvsRes : []);
            setLoadingSpinner(false);
          } else {
            setLoadingSpinner(false);
          }
        } catch (error) {
          console.error("Ошибка при выполнении запроса: ", error);
        }
      })();
    },
    [setFilteredUserData, setLoadingSpinner, ownUserInfo, usernames.length]
  );

  useEffect(() => {
    getPositionsData();
    getKeySkillsData();
    getContractTypesData();
  }, [getPositionsData, getKeySkillsData, getContractTypesData]);

  useEffect(() => {
    if (searchItems.length !== null) {
      if (!searchItems.includes(searchParamList[0].value)) setUser({});
      if (!searchItems.includes(searchParamList[1].value)) setPosition({});
      if (!searchItems.includes(searchParamList[2].value)) setTechSkills([]);
      if (!searchItems.includes(searchParamList[3].value)) setProject({});
      if (!searchItems.includes(searchParamList[4].value)) setKeySkills([]);
      if (!searchItems.includes(searchParamList[5].value)) setEnglishLevel({});
      if (!searchItems.includes(searchParamList[6].value)) setIsFired({});
      if (!searchItems.includes(searchParamList[7].value)) setContractType({});
    }
  }, [searchItems]);

  const handleSearch = useCallback(() => {
    const arr = [].concat(
      user,
      project,
      position,
      englishLevel,
      selectedTechSkills,
      selectedKeySkills,
      contractType,
    );

    let queryString = arr
      .filter((item) => item.value && item.value.length > 0)
      .map((item) => {
        const { searchParam, value } = item;
        const encodedValue = Array.isArray(value) ? value.map(v => encodeURIComponent(v)).join(',') : encodeURIComponent(value);
        return `${searchParam}_contains=${encodedValue}`;
      })
      .join('&');

    const separator = queryString === '' ? '' : '&';

    if (valueIsFired === 'Fired') {
      queryString += `${separator}isFired=true`;
    } else if (valueIsFired === 'Not Fired') {
      queryString += `${separator}isFired=false`;
    }

    getSearchResultsData(queryString);
  }, [
    user,
    project,
    position,
    englishLevel,
    selectedKeySkills,
    selectedTechSkills,
    valueIsFired,
    contractType,
    getSearchResultsData,
  ]);

  useEffect(() => {
    handleSearch();
  }, [
    user,
    project,
    position,
    englishLevel,
    selectedKeySkills,
    selectedTechSkills,
    valueIsFired,
    contractType,
    handleSearch,
  ]);

  return (
    <Space size="small" direction="vertical" style={{ width: '100%' }}>
      <OptionFilter
        placeholder="Select Option"
        options={searchParamList}
        setSearchItems={setSearchItems}
      />
      <UserNameFilter
        searchItems={searchItems}
        usernames={usernames}
        setUser={setUser}
        setUserNames={setUserNames}
      />
      <PositionFilter
        searchItems={searchItems}
        positionList={positionList}
        setPosition={setPosition}
      />
      <TechSkillsFilter
        searchItems={searchItems}
        setTechSkills={setTechSkills}
      />
      <ProjectFilter
        projects={projects}
        searchItems={searchItems}
        setProject={setProject}
      />
      <KeySkillsFilter
        searchItems={searchItems}
        keySkillList={keySkillList}
        setKeySkills={setKeySkills}
        setSearchItems={setSearchItems}
      />
      <EnglishFilter
        searchItems={searchItems}
        setEnglishLevel={setEnglishLevel}
      />
      <IsFireFilter
        searchItems={searchItems}
        setIsFired={setIsFired}
      />
      <ContractTypeFilter
        searchItems={searchItems}
        contractTypes={contractTypes}
        setContractType={setContractType}
      />
    </Space>
  );
}
