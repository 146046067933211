import config from '../config';
import { getElementFromStorage } from '../utils/storage';

export const CoppyResumeItemById = (itemId, itemName) => {
  const reqHeaders = new Headers();
  const token = getElementFromStorage('jwtToken');

  reqHeaders.append('Authorization', `Bearer ${token}`);
  reqHeaders.append('Content-Type', 'application/json');

  const data = JSON.stringify({
    id: itemId,
    name: itemName && `${itemName} copy`,
    isMain: false,
  });

  const requestOptions = {
    method: 'POST',
    headers: reqHeaders,
    body: data,
  };

  const BaseURL = `${config.http.BASE_URL}/resume-items/copy`;

  return fetch(BaseURL, requestOptions).then(
    (response) =>
      response.status !== 200
        ? {
            error: true,
          }
        : response
            .json()
            .then((json) => json)
            .catch(() => ({
              error: true,
            })),
    (error) => {
      throw new Error(error.message);
    },
  );
};

export default CoppyResumeItemById;
