import React, { useRef, useContext } from 'react';
import Tooltip from '@material-ui/core/Tooltip';

import logoIco from '../assets/images/icons/softteco_logo.png';
import QuestionIcon from '../assets/images/icons/support.svg'

import AppContext from '../context/AppContext';

const TabBar = ({ tabs, currentTab, setCurrentTab, showTab, setShowTab, handleFeedBackPopup }) => {
  const tabsRef = useRef(null);

  const context = useContext(AppContext);
  const { state } = context;
  const { isSale, isAdmin } = state;

  const onTabClick = (tabKey, event) => {
    if (tabKey === 'feedback') {
      handleFeedBackPopup(event);
    } else {
      if (!showTab) setShowTab(true);
      setCurrentTab(tabKey);
    }
  };

  const toggleTabAppearance = () => setShowTab(!showTab);

  const clickMenuButton = (tabKey, event) => {
    if (isAdmin || isSale) toggleTabAppearance();
    else onTabClick(tabKey, event);
  }

  return (
    <div className="tab_bar">
      {isAdmin || isSale ? (
        <Tooltip title="Show / hide the edit bar">
          <button className="tabBarLogoBlock" onClick={toggleTabAppearance}>
            <img src={logoIco} className="logo_chr" alt="" />
          </button>
        </Tooltip>
      ) : (
        <div className="tabBarLogoBlock">
          <img src={logoIco} className="logo_chr" alt="" />
        </div>
      )}
      <ul id="tabs" ref={tabsRef} className="grid grid-cols-1 list-none mt-6">
        {tabs.map((tab) =>
          currentTab === tab.key ? (
            <li key={tab.key} className={`mx-2 list-none mb-1 ${tab.key === 'cvSettings' && 'settings_tab pb-1'} ${tab.key}`}>
              <Tooltip title={tab.title} placement="right">
                <div
                  className="leftSidebar_content relative bg-gray-200 text-white rounded-md text-sm flex items-center justify-center font-medium"
                  onClick={(event) => clickMenuButton(tab.key, event)}
                >
                  {tab.icon ? (
                    <img src={tab.icon} className={`${tab.key === "cvSettings" ? "tab_settings_icon" : "tab_icon"} my-2`} alt="tab" />
                  ) : (
                    <img src={QuestionIcon} className="tab_icon my-2" alt="tab" />
                  )}
                  <div className='leftSidebar_text'>{tab.title}</div>
                  {tab.validation && <span className="notification"> </span>}
                </div>
              </Tooltip>
            </li>
          ) : (
            <li
              key={tab.key}
              className={`mx-2 list-none ${tab.key} ${tab.key === 'cvSettings' && 'settings_tab mb-1'}`}
            >
              <Tooltip title={tab.title} placement="right">
                <div
                  className="leftSidebar_content relative bg-white rounded-md cursor-pointer text-sm font-medium flex items-center justify-center hover:bg-gray-200 mb-1"
                  onClick={(event) => onTabClick(tab.key, event)}
                >
                  {tab.icon ? (
                    <img src={tab.icon} className={`${tab.key === "cvSettings" ? "tab_settings_icon" : "tab_icon"} my-2`} alt="tab" />
                  ) : (
                    <img src={QuestionIcon} className="tab_icon my-2" alt="tab" />
                  )}
                  <div className='leftSidebar_text'>{tab.title}</div>
                  {tab.validation && <span className="notification"> </span>}
                </div>
              </Tooltip>
            </li>
          ),
        )}
      </ul>
    </div>
  );
};

export default TabBar;
