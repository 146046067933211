import React from 'react';
import { useTranslation } from 'react-i18next';
import { get, remove } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import TextField from '../../../shared/TextField';
import TextArea from '../../../shared/TextArea';
import Dropdown from '../../../shared/Dropdown';
import { unshiftItem, deleteItem } from '../../../utils';
import { GetPositions } from '../../../services/GetPositions';
// eslint-disable-next-line import/no-named-as-default
import config from '../../../config';
import { yearsList } from '../../../helpers/AppHelpers';
import { beautifyEmployeeDescription } from '../../../services/BeautifierAPI';
import { useBeautifier, InputBeautifier } from '../../../shared/InputBeautifier';

const SummaryTab = ({ data, onChange, dispatch }) => {
  const { t } = useTranslation('leftSidebar');

  const [positions, setPositions] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      let positionsFromRes = await GetPositions();
      positionsFromRes = positionsFromRes.sort((a, b) =>
        get(a, 'title').localeCompare(get(b, 'title')),
      );
      const testArr = {
        id: 'Select position',
        title: '',
        disabled: true,
      };
      positionsFromRes.unshift(testArr);
      setPositions(positionsFromRes);
    })();
  }, []);

  const getDescriptionToBeautify = () => {
    return {
      name: data.summary.firstName,
      level: data.summary.position_level,
      position: data.summary.position,
      years: data.summary.experience,
      description: data.summary.body,
    };
  };

  const descriptionBeautifier = useBeautifier(
    beautifyEmployeeDescription,
    getDescriptionToBeautify,
  );

  const findTypeByIdTab = (type) => {
    switch (type) {
      case config.TECH_SKILLS_TABS.versionControlSystems:
        type = 'versionControlSystems';
        return type;
      case config.TECH_SKILLS_TABS.developmentTechnologies:
        type = 'developmentTechnologies';
        return type;
      case config.TECH_SKILLS_TABS.programmingLanguages:
        type = 'programmingLanguages';
        return type;
      case config.TECH_SKILLS_TABS.ideOrTools:
        type = 'ideOrTools';
        return type;
      default:
        type = '';
        return type;
    }
  };

  const findItem = (idTab, skillTitle) => {
    idTab = findTypeByIdTab(idTab);
    if (idTab === '') return false;
    return data[idTab]?.items?.some(
      (skill) => skill.key === skillTitle && skill.isDefault === true && skill.wasChanged === true,
    );
  };

  const clearDefaultSkills = () => {
    data.programmingLanguages.items
      .filter(
        (itemDefault) =>
          get(itemDefault, 'isDefault') === true && get(itemDefault, 'wasChanged') === false,
      )
      .forEach((item) => {
        deleteItem(dispatch, 'programmingLanguages', item);
      });
    data.ideOrTools.items
      .filter(
        (itemDefault) =>
          get(itemDefault, 'isDefault') === true && get(itemDefault, 'wasChanged') === false,
      )
      .forEach((item) => {
        deleteItem(dispatch, 'ideOrTools', item);
      });
    data.developmentTechnologies.items
      .filter(
        (itemDefault) =>
          get(itemDefault, 'isDefault') === true && get(itemDefault, 'wasChanged') === false,
      )
      .forEach((item) => {
        deleteItem(dispatch, 'developmentTechnologies', item);
      });
    data.versionControlSystems.items
      .filter(
        (itemDefault) =>
          get(itemDefault, 'isDefault') === true && get(itemDefault, 'wasChanged') === false,
      )
      .forEach((item) => {
        deleteItem(dispatch, 'versionControlSystems', item);
      });
  };

  const addDefaultSkills = (value) => {
    onChange('data.summary.position', value);
    const testItem = positions.find((test) => test.title === value);
    const defaultSkills = [...testItem.autocomplete_tech_skills];
    const defaultSkillsWithoutDuplicates = remove(
      defaultSkills,
      (item) => !findItem(get(item, 'tab'), get(item, 'skill_title')),
    );
    defaultSkillsWithoutDuplicates.forEach((item) => {
      const skill = {
        id: uuidv4(),
        enable: true,
        key: get(item, 'skill_title'),
        years: '',
        level: '',
        disabled: false,
        disabledAC: false,
        isDefault: true,
        wasChanged: false,
        tabId: get(item, 'tab'),
        isSkillList: true,
      };
      const type = findTypeByIdTab(get(item, 'tab'));
      unshiftItem(dispatch, type, skill);
    });
    clearDefaultSkills();
  };

  const positionLevel = [
    { key: 'Select level', value: '', disabled: true },
    { key: '', value: '' },
    { key: 'Junior', value: `${t('summary.position_level.junior')}` },
    { key: 'Middle', value: `${t('summary.position_level.middle')}` },
    { key: 'Senior', value: `${t('summary.position_level.senior')}` },
    { key: 'Lead', value: `${t('summary.position_level.lead')}` },
  ];

  return (
    <div>
      <div className="title_tab mb23">{t('summary.title.label')}</div>

      <hr className="my-6" />

      <div className="grid grid-cols-2 col-gap-4">
        <TextField
          className="mb-6"
          label={t('summary.firstName.label')}
          placeholder={t('summary.firstName.placeholder')}
          value={data.summary.firstName}
          onChange={(v) => onChange('data.summary.firstName', v)}
          tooltip={'right'}
          tooltipType={'summary'}
          tooltipLabel={t('summary.firstName.label')}
        />

        <TextField
          className="mb-6"
          label={t('summary.lastName.label')}
          placeholder={t('summary.lastName.placeholder')}
          value={data.summary.lastName}
          onChange={(v) => onChange('data.summary.lastName', v)}
          tooltip={'right'}
          tooltipType={'summary'}
          tooltipLabel={t('summary.lastName.label')}
        />
      </div>

      <Dropdown
        className="mb-6"
        label={t('summary.position.label')}
        value={data.summary.position}
        options={positions}
        onChange={(v) => addDefaultSkills(v)}
        optionItem={(x) => (
          <option key={x.id} value={x.title} selected={x.disabled} disabled={x.disabled}>
            {x.disabled ? 'Select Position' : x.title}
          </option>
        )}
        tooltip={'right'}
        tooltipType={'summary'}
        tooltipLabel={t('summary.position.label')}
      />

      <Dropdown
        className="mb-6"
        label={t('summary.position_level.label')}
        value={data.summary.position_level}
        options={positionLevel}
        onChange={(v) => onChange('data.summary.position_level', v)}
        optionItem={(x) => (
          <option key={x.key} value={x.value} selected={x.disabled} disabled={x.disabled}>
            {x.disabled ? 'Select Position Level' : x.value}
          </option>
        )}
        tooltip={'right'}
        tooltipType={'summary'}
        tooltipLabel={t('summary.position_level.label')}
      />

      <Dropdown
        label={t('summary.experience.label')}
        value={data.summary.experience}
        options={yearsList}
        onChange={(v) => onChange('data.summary.experience', v)}
        optionItem={(x) => (
          <option key={x.key} value={x.value} selected={x.disabled} disabled={x.disabled}>
            {x.disabled ? 'Select years' : x.value}
          </option>
        )}
        tooltip={'right'}
        tooltipType={'summary'}
        tooltipLabel={t('summary.experience.label')}
      />

      <hr className="my-6" />

      <InputBeautifier beautifier={descriptionBeautifier}>
        <TextArea
          rows="15"
          className="mb-1"
          label={t('summary.description.label')}
          value={data.summary.body}
          validation={data.summary.body.length > 0 && data.summary.body.length < 220}
          placeholder={t('summary.description.placeholder')}
          onChange={(v) => onChange('data.summary.body', v, 'summary.description')}
          maxLength={550}
          tooltip={'right'}
          tooltipType={'summary'}
          tooltipLabel={t('summary.description.label')}
          position={data.summary.position}
        />
        {data.summary.body.length > 0 && data.summary.body.length < 220 && (
          <span className="text-red-500 text-sm block leading-1">
            {t('summary.description.error_text')}
          </span>
        )}
      </InputBeautifier>
    </div>
  );
};

export default SummaryTab;
