import approvedMarkIco from '../assets/images/icons/approvedMark.png';
import notApprovedMarkIco from '../assets/images/icons/notApprovedMark.png';
import modifiedMarkIco from '../assets/images/icons/modifiedMark.png';
import awaitMarkIco from '../assets/images/icons/awaitMark.png';
import expiredMarkIco from '../assets/images/icons/expiredMark.png';

import languagesIcon from '../assets/images/icons/languages.svg'
import educationIcon from '../assets/images/icons/education.svg'
import skillsIcon from '../assets/images/icons/skills.svg'
import programmingLanguagesIcon from '../assets/images/icons/programming-languages.svg'
import databasesIcon from '../assets/images/icons/databases.svg'
import ideIcon from '../assets/images/icons/ide.svg'
import devTechnologiesIcon from '../assets/images/icons/dev-technologies.svg'
import ciCdIcon from '../assets/images/icons/ci-cd.svg'
import issueTrackingIcon from '../assets/images/icons/issue-tracking.svg'
import domainsIcon from '../assets/images/icons/domains.svg'
import iosIcon from '../assets/images/icons/ios.svg'
import androidIcon from '../assets/images/icons/android.svg'
import methodologyIcon from '../assets/images/icons/methodologies.svg'
import otherSkillsIcon from '../assets/images/icons/other-skills.svg'
import supportIcon from '../assets/images/icons/support.svg'
import projectsIcon from '../assets/images/icons/projects.svg'

export const dateFormat = 'dd/MM/yyyy';
export const monthYearFormat = 'MMMM yyyy';
export const shortMonthYearFormat = 'MMM yyyy';

export const companyName = 'softteco';

export const yearsList = [
  { key: 'Select years', value: '', disabled: true },
  { key: 1, value: 1 },
  { key: 2, value: 2 },
  { key: 3, value: 3 },
  { key: 4, value: 4 },
  { key: 5, value: 5 },
  { key: 6, value: 6 },
  { key: 7, value: 7 },
  { key: 8, value: 8 },
  { key: 9, value: 9 },
  { key: 10, value: 10 },
  { key: 11, value: 11 },
  { key: 12, value: 12 },
  { key: 13, value: 13 },
  { key: 14, value: 14 },
  { key: 15, value: 15 },
  { key: 16, value: 16 },
  { key: 17, value: 17 },
  { key: 18, value: 18 },
  { key: 19, value: 19 },
  { key: 20, value: 20 },
  { key: 21, value: 21 },
  { key: 22, value: 22 },
  { key: 23, value: 23 },
  { key: 24, value: 24 },
  { key: 25, value: 25 },
  { key: 26, value: 26 },
  { key: 27, value: 27 },
  { key: 28, value: 28 },
  { key: 29, value: 29 },
  { key: 30, value: 30 },
];

export const clearCvModel = {
  name: "Main CV",
  isMain: true,
  summary: {
    enable: true,
    heading: 'Summary',
    firstName: '',
    lastName: '',
    position: '',
    position_level: '',
    body: '',
    experience: '',
  },
  education: {
    enable: true,
    heading: 'Education',
    items: [],
  },
  languages: {
    enable: true,
    heading: 'Languages',
    items: [],
  },
  programmingLanguages: {
    enable: true,
    heading: 'Programming Lang.',
    items: [],
  },
  dataBases: {
    enable: true,
    heading: 'Databases',
    items: [],
  },
  ideOrTools: {
    enable: true,
    heading: 'IDE/Development Tools',
    items: [],
  },
  developmentTechnologies: {
    enable: true,
    heading: 'Development Technologies',
    items: [],
  },
  ciCdTools: {
    enable: true,
    heading: 'CI/CD Tools',
    items: [],
  },
  versionControlSystems: {
    enable: true,
    heading: 'Issue Tracking / Version Control System',
    items: [],
  },
  domains: {
    enable: true,
    heading: 'Domains',
    items: [],
  },
  certifications: {
    enable: true,
    heading: 'Certifications',
    items: [],
  },
  iOsTechnologies: {
    enable: true,
    heading: 'iOS Technologies',
    items: [],
  },
  androidTechnologies: {
    enable: true,
    heading: 'Android Technologies',
    items: [],
  },
  developmentMethodologies: {
    enable: true,
    heading: 'Development Methodologies',
    items: [],
  },
  other: {
    enable: true,
    heading: 'Other Skills',
    items: [],
  },
  skills: {
    enable: true,
    heading: 'Skills',
    items: [],
  },
  projects: {
    enable: true,
    heading: 'Projects',
    items: [],
  },
};

export const tabsExceptSummary = [
  { key: 'languages', icon: languagesIcon, title: 'Languages' },
  { key: 'education', icon: educationIcon, title: 'Education' },
  { key: 'skills', icon: skillsIcon, title: 'Skills' },
  { key: 'domains', icon: domainsIcon, title: 'Domains' },
  { key: 'programmingLanguages', icon: programmingLanguagesIcon, title: 'Programming Languages' },
  { key: 'dataBases', icon: databasesIcon, title: 'Databases' },
  { key: 'ideOrTools', icon: ideIcon, title: 'IDE / Dev Tools' },
  { key: 'developmentTechnologies', icon: devTechnologiesIcon, title: 'Dev Technologies' },
  { key: 'ciCdTools', icon: ciCdIcon, title: 'CI/CD Tools' },
  { key: 'versionControlSystems', icon: issueTrackingIcon, title: 'Issue Tracking / Version Control Systems' },
  { key: 'iOsTechnologies', icon: iosIcon, title: 'iOS' },
  { key: 'androidTechnologies', icon: androidIcon, title: 'Android' },
  { key: 'developmentMethodologies', icon: methodologyIcon, title: 'Methodologies' },
  { key: 'other', icon: otherSkillsIcon, title: 'Other Skills' },
  { key: 'projects', icon: projectsIcon, title: 'Projects' },
  { key: 'feedback', icon: supportIcon, title: 'Support' }
];

export const langLevels = [
  { key: 'Select level', value: '', level: '', disabled: true },
  { key: 'Elementary', value: 'Elementary', level: 'Limited Working Proficiency', },
  { key: 'Pre-Intermediate', value: 'Pre-Intermediate', level: 'Limited Working Proficiency', },
  { key: 'A2+ (Pre Intermediate+)', value: 'A2+ (Pre Intermediate+)', level: 'Limited Working Proficiency', },
  { key: 'Intermediate', value: 'Intermediate', level: 'Professional Working Proficiency', },
  { key: 'B1+ (Intermediate+)', value: 'B1+ (Intermediate+)', level: 'Professional Working Proficiency', },
  { key: 'Upper-Intermediate', value: 'Upper-Intermediate', level: 'Professional Working Proficiency', },
  { key: 'B2+ (Upper Intermediate+)', value: 'B2+ (Upper Intermediate+)', level: 'Professional Working Proficiency', },
  { key: 'Advanced', value: 'Advanced', level: 'Full Professional Proficiency', },
  { key: 'C2 (Proficiency)', value: 'C2 (Proficiency)', level: 'Full Professional Proficiency', },
  { key: 'Native', value: 'Native', level: 'Native', },
];

export const changeLanguageName = (value) => {
  if (value === 'Elementary') {
    return 'A1 (Elementary)'
  }
  if (value === 'Pre-Intermediate') {
    return 'A2 (Pre Intermediate)'
  }
  if (value === 'Intermediate') {
    return 'B1 (Intermediate)'
  }
  if (value === 'Upper-Intermediate') {
    return 'B2 (Upper Intermediate)'
  }
  if (value === 'Advanced') {
    return 'C1 (Advanced)'
  }
  return value
}

export const switchLanguageName = (value) => {
  switch (value) {
    case 'Elementary':
      return 'A1 (Elementary)';
    case 'Pre-Intermediate':
      return 'A2 (Pre Intermediate)';
    case 'Intermediate':
      return 'B1 (Intermediate)';
    case 'Upper-Intermediate':
      return 'B2 (Upper Intermediate)';
    case 'Advanced':
      return 'C1 (Advanced)';
    default:
      return value;
  }
};

export const replaceLangLevel = (level) => {
  const langLevel = langLevels.find(langLevel => langLevel.key === level);
  return langLevel ? langLevel.level : '';
}

export const transformCvData = (cvData) => {
  if (cvData.languages && Array.isArray(cvData.languages.items)) {
    const newCvData = { ...cvData };
    newCvData.languages.items = cvData.languages.items.map(item => {
      if (item.level) {
        return { ...item, level: replaceLangLevel(item.level) };
      }
      return item;
    });
    return newCvData;
  }
  return cvData;
}

export const getMarkIcon = (data) => {
  switch (data.cvStatus) {
    case 'approved':
      return approvedMarkIco;
    case 'notApproved':
      return notApprovedMarkIco;
    case 'modified':
      return modifiedMarkIco;
    case 'expired':
      return expiredMarkIco;
    case 'awaitingApproval':
      return awaitMarkIco;
    default:
      return notApprovedMarkIco;
  }
};

export function isValidDatePickerDate(date) {
  const isDate = new Date(date).toString() !== 'Invalid Date';
  const isNull = date === null;
  return isDate || isNull;
};

export const sortProjects = (data) => {
  const compareFunc = (a, b) => {
    // month & year comparison
    function compareDate(x, y) {
      let result = 0;
      if (x.getFullYear() !== y.getFullYear()) {
        result = x.getFullYear() - y.getFullYear();
      } else {
        result = x.getMonth() - y.getMonth();
      }
      return result;
    };

    const dateAStart = new Date(a.start);
    const dateBStart = new Date(b.start);
    const dateAEnd = a.end ? new Date(a.end) : new Date();
    const dateBEnd = b.end ? new Date(b.end) : new Date();
    const endCompare = compareDate(dateBEnd, dateAEnd);
    let result = 0;
    if (endCompare) {
      result = endCompare;
    } else {
      result = compareDate(dateBStart, dateAStart);
    }
    return result;
  };

  const dataCopy = JSON.parse(JSON.stringify(data));
  dataCopy.projects.items.sort(compareFunc);
  return dataCopy;
};

export const isEqualProjects = (projectsItemsArray1, projectsItemsArray2) => {
  let equal = true;
  if (JSON.stringify(projectsItemsArray1) !== JSON.stringify(projectsItemsArray2)) {
    if (projectsItemsArray1.length !== projectsItemsArray2.length) {
      equal = false;
    } else {
      const filter = projectsItemsArray1?.filter((value, index) => {
        return !(value.start === projectsItemsArray2[index].start &&
          value.end === projectsItemsArray2[index].end);
        // projectsItemsArray2[index].start , if moving projects IS a condition to sort
        // projectsItemsArray2.find(v => v.start === value.start).start , if moving projects is NOT a condition to sort
      });
      if (filter.length) {
        equal = false;
      }
    }
  }
  return equal;
};

export const searchParamList = [
  { value: 'user', label: 'User', placeholder: 'User Name' },
  { value: 'position', label: 'Position', placeholder: 'Select Position' },
  { value: 'techSkills', label: 'Technical Skills', placeholder: 'Technical Skills' },
  { value: 'project', label: 'Project Name', placeholder: 'Project Name' },
  { value: 'keySkills', label: 'Key Skills', placeholder: 'Select Key Skills' },
  { value: 'english', label: 'English Level', placeholder: 'Select Minimum Level' },
  { value: 'isFired', label: 'Fired', placeholder: 'Select Fired Option' },
  { value: 'contractType', label: 'Contract Type', placeholder: 'Select Contract Type' },
];

export const isFired = [{ value: 'Fired' }, { value: 'Not Fired' }];

export const maxCountResumeItems = 10;

export const countries = [
  { key: 'Belarus', value: 'Belarus' },
  { key: 'Afghanistan', value: 'Afghanistan' },
  { key: 'Albania', value: 'Albania' },
  { key: 'Algeria', value: 'Algeria' },
  { key: 'Andorra', value: 'Andorra' },
  { key: 'Angola', value: 'Angola' },
  { key: 'Antigua and Barbuda', value: 'Antigua and Barbuda' },
  { key: 'Argentina', value: 'Argentina' },
  { key: 'Armenia', value: 'Armenia' },
  { key: 'Australia', value: 'Australia' },
  { key: 'Austria', value: 'Austria' },
  { key: 'Azerbaijan', value: 'Azerbaijan' },
  { key: 'Bahamas', value: 'Bahamas' },
  { key: 'Bahrain', value: 'Bahrain' },
  { key: 'Bangladesh', value: 'Bangladesh' },
  { key: 'Barbados', value: 'Barbados' },
  { key: 'Belgium', value: 'Belgium' },
  { key: 'Belize', value: 'Belize' },
  { key: 'Benin', value: 'Benin' },
  { key: 'Bhutan', value: 'Bhutan' },
  { key: 'Bolivia', value: 'Bolivia' },
  { key: 'Bosnia and Herzegovina', value: 'Bosnia and Herzegovina' },
  { key: 'Botswana', value: 'Botswana' },
  { key: 'Brazil', value: 'Brazil' },
  { key: 'Brunei', value: 'Brunei' },
  { key: 'Bulgaria', value: 'Bulgaria' },
  { key: 'Burkina Faso', value: 'Burkina Faso' },
  { key: 'Burundi', value: 'Burundi' },
  { key: 'Cabo Verde', value: 'Cabo Verde' },
  { key: 'Cambodia', value: 'Cambodia' },
  { key: 'Cameroon', value: 'Cameroon' },
  { key: 'Canada', value: 'Canada' },
  { key: 'Central African Republic', value: 'Central African Republic' },
  { key: 'Chad', value: 'Chad' },
  { key: 'Chile', value: 'Chile' },
  { key: 'China', value: 'China' },
  { key: 'Colombia', value: 'Colombia' },
  { key: 'Comoros', value: 'Comoros' },
  { key: 'Congo, Democratic Republic of the', value: 'Congo, Democratic Republic of the' },
  { key: 'Congo, Republic of the', value: 'Congo, Republic of the' },
  { key: 'Costa Rica', value: 'Costa Rica' },
  { key: 'Croatia', value: 'Croatia' },
  { key: 'Cuba', value: 'Cuba' },
  { key: 'Cyprus', value: 'Cyprus' },
  { key: 'Czech Republic', value: 'Czech Republic' },
  { key: 'Denmark', value: 'Denmark' },
  { key: 'Djibouti', value: 'Djibouti' },
  { key: 'Dominica', value: 'Dominica' },
  { key: 'Dominican Republic', value: 'Dominican Republic' },
  { key: 'Ecuador', value: 'Ecuador' },
  { key: 'Egypt', value: 'Egypt' },
  { key: 'El Salvador', value: 'El Salvador' },
  { key: 'Equatorial Guinea', value: 'Equatorial Guinea' },
  { key: 'Eritrea', value: 'Eritrea' },
  { key: 'Estonia', value: 'Estonia' },
  { key: 'Eswatini', value: 'Eswatini' },
  { key: 'Ethiopia', value: 'Ethiopia' },
  { key: 'Fiji', value: 'Fiji' },
  { key: 'Finland', value: 'Finland' },
  { key: 'France', value: 'France' },
  { key: 'Gabon', value: 'Gabon' },
  { key: 'Gambia', value: 'Gambia' },
  { key: 'Georgia', value: 'Georgia' },
  { key: 'Germany', value: 'Germany' },
  { key: 'Ghana', value: 'Ghana' },
  { key: 'Greece', value: 'Greece' },
  { key: 'Grenada', value: 'Grenada' },
  { key: 'Guatemala', value: 'Guatemala' },
  { key: 'Guinea', value: 'Guinea' },
  { key: 'Guinea-Bissau', value: 'Guinea-Bissau' },
  { key: 'Guyana', value: 'Guyana' },
  { key: 'Haiti', value: 'Haiti' },
  { key: 'Honduras', value: 'Honduras' },
  { key: 'Hungary', value: 'Hungary' },
  { key: 'Iceland', value: 'Iceland' },
  { key: 'India', value: 'India' },
  { key: 'Indonesia', value: 'Indonesia' },
  { key: 'Iran', value: 'Iran' },
  { key: 'Iraq', value: 'Iraq' },
  { key: 'Ireland', value: 'Ireland' },
  { key: 'Israel', value: 'Israel' },
  { key: 'Italy', value: 'Italy' },
  { key: 'Jamaica', value: 'Jamaica' },
  { key: 'Japan', value: 'Japan' },
  { key: 'Jordan', value: 'Jordan' },
  { key: 'Kazakhstan', value: 'Kazakhstan' },
  { key: 'Kenya', value: 'Kenya' },
  { key: 'Kiribati', value: 'Kiribati' },
  { key: 'Korea, North', value: 'Korea, North' },
  { key: 'Korea, South', value: 'Korea, South' },
  { key: 'Kuwait', value: 'Kuwait' },
  { key: 'Kyrgyzstan', value: 'Kyrgyzstan' },
  { key: 'Laos', value: 'Laos' },
  { key: 'Latvia', value: 'Latvia' },
  { key: 'Lebanon', value: 'Lebanon' },
  { key: 'Lesotho', value: 'Lesotho' },
  { key: 'Liberia', value: 'Liberia' },
  { key: 'Libya', value: 'Libya' },
  { key: 'Liechtenstein', value: 'Liechtenstein' },
  { key: 'Lithuania', value: 'Lithuania' },
  { key: 'Luxembourg', value: 'Luxembourg' },
  { key: 'Madagascar', value: 'Madagascar' },
  { key: 'Malawi', value: 'Malawi' },
  { key: 'Malaysia', value: 'Malaysia' },
  { key: 'Maldives', value: 'Maldives' },
  { key: 'Mali', value: 'Mali' },
  { key: 'Malta', value: 'Malta' },
  { key: 'Marshall Islands', value: 'Marshall Islands' },
  { key: 'Mauritania', value: 'Mauritania' },
  { key: 'Mauritius', value: 'Mauritius' },
  { key: 'Mexico', value: 'Mexico' },
  { key: 'Micronesia', value: 'Micronesia' },
  { key: 'Moldova', value: 'Moldova' },
  { key: 'Monaco', value: 'Monaco' },
  { key: 'Mongolia', value: 'Mongolia' },
  { key: 'Montenegro', value: 'Montenegro' },
  { key: 'Morocco', value: 'Morocco' },
  { key: 'Mozambique', value: 'Mozambique' },
  { key: 'Myanmar', value: 'Myanmar' },
  { key: 'Namibia', value: 'Namibia' },
  { key: 'Nauru', value: 'Nauru' },
  { key: 'Nepal', value: 'Nepal' },
  { key: 'Netherlands', value: 'Netherlands' },
  { key: 'New Zealand', value: 'New Zealand' },
  { key: 'Nicaragua', value: 'Nicaragua' },
  { key: 'Niger', value: 'Niger' },
  { key: 'Nigeria', value: 'Nigeria' },
  { key: 'North Macedonia', value: 'North Macedonia' },
  { key: 'Norway', value: 'Norway' },
  { key: 'Oman', value: 'Oman' },
  { key: 'Pakistan', value: 'Pakistan' },
  { key: 'Palau', value: 'Palau' },
  { key: 'Palestine', value: 'Palestine' },
  { key: 'Panama', value: 'Panama' },
  { key: 'Papua New Guinea', value: 'Papua New Guinea' },
  { key: 'Paraguay', value: 'Paraguay' },
  { key: 'Peru', value: 'Peru' },
  { key: 'Philippines', value: 'Philippines' },
  { key: 'Poland', value: 'Poland' },
  { key: 'Portugal', value: 'Portugal' },
  { key: 'Qatar', value: 'Qatar' },
  { key: 'Romania', value: 'Romania' },
  { key: 'Russia', value: 'Russia' },
  { key: 'Rwanda', value: 'Rwanda' },
  { key: 'Saint Kitts and Nevis', value: 'Saint Kitts and Nevis' },
  { key: 'Saint Lucia', value: 'Saint Lucia' },
  { key: 'Saint Vincent and the Grenadines', value: 'Saint Vincent and the Grenadines' },
  { key: 'Samoa', value: 'Samoa' },
  { key: 'San Marino', value: 'San Marino' },
  { key: 'Sao Tome and Principe', value: 'Sao Tome and Principe' },
  { key: 'Saudi Arabia', value: 'Saudi Arabia' },
  { key: 'Senegal', value: 'Senegal' },
  { key: 'Serbia', value: 'Serbia' },
  { key: 'Seychelles', value: 'Seychelles' },
  { key: 'Sierra Leone', value: 'Sierra Leone' },
  { key: 'Singapore', value: 'Singapore' },
  { key: 'Slovakia', value: 'Slovakia' },
  { key: 'Slovenia', value: 'Slovenia' },
  { key: 'Solomon Islands', value: 'Solomon Islands' },
  { key: 'Somalia', value: 'Somalia' },
  { key: 'South Africa', value: 'South Africa' },
  { key: 'South Sudan', value: 'South Sudan' },
  { key: 'Spain', value: 'Spain' },
  { key: 'Sri Lanka', value: 'Sri Lanka' },
  { key: 'Sudan', value: 'Sudan' },
  { key: 'Suriname', value: 'Suriname' },
  { key: 'Sweden', value: 'Sweden' },
  { key: 'Switzerland', value: 'Switzerland' },
  { key: 'Syria', value: 'Syria' },
  { key: 'Taiwan', value: 'Taiwan' },
  { key: 'Tajikistan', value: 'Tajikistan' },
  { key: 'Tanzania', value: 'Tanzania' },
  { key: 'Thailand', value: 'Thailand' },
  { key: 'Timor-Leste', value: 'Timor-Leste' },
  { key: 'Togo', value: 'Togo' },
  { key: 'Tonga', value: 'Tonga' },
  { key: 'Trinidad and Tobago', value: 'Trinidad and Tobago' },
  { key: 'Tunisia', value: 'Tunisia' },
  { key: 'Turkey', value: 'Turkey' },
  { key: 'Turkmenistan', value: 'Turkmenistan' },
  { key: 'Tuvalu', value: 'Tuvalu' },
  { key: 'Uganda', value: 'Uganda' },
  { key: 'Ukraine', value: 'Ukraine' },
  { key: 'United Arab Emirates', value: 'United Arab Emirates' },
  { key: 'United Kingdom', value: 'United Kingdom' },
  { key: 'United States', value: 'United States' },
  { key: 'Uruguay', value: 'Uruguay' },
  { key: 'Uzbekistan', value: 'Uzbekistan' },
  { key: 'Vanuatu', value: 'Vanuatu' },
  { key: 'Vatican City', value: 'Vatican City' },
  { key: 'Venezuela', value: 'Venezuela' },
  { key: 'Vietnam', value: 'Vietnam' },
  { key: 'Yemen', value: 'Yemen' },
  { key: 'Zambia', value: 'Zambia' },
  { key: 'Zimbabwe', value: 'Zimbabwe' }
];
