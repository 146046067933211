import config from "../config";

export const getDomains = () => {
  const reqHeaders = new Headers();
  const BaseURL = `${config.http.BASE_URL}/key-skills/domains`;
  return fetch(BaseURL, { method: 'GET', headers: reqHeaders })
    .then(response => (response.status !== 200 ? ({
      error: true,
    }) : response.json().then(json => json)
      .catch(() => ({
        error: true,
      }))), (error) => {
        throw new Error(error.message);
      });
};

export default getDomains;
