import React from 'react'
import { Tooltip } from 'antd';
import AlertIcon from '../../assets/images/alert-circle.png'
import { tooltypesTexts } from '../constants';

const InfoContent = ({ data, label, position }) => {
  const tooltipData = tooltypesTexts[data];
  if (label) {
    const instruction = tooltipData[label]?.instruction || "That's what it is."
    let example = tooltipData[label]?.example
    if (label === 'Employee’s description') {
      example = example[position] || example['default']
    }
    return (
      <div>
        <div>
          {instruction.split('<br/>').map((text, index, array) => (
            <div key={index}>
              {text}{index !== array.length - 1 && <br />}
            </div>
          ))}
        </div>
        {example && <>
          <div className='font-light mt-3'>Example:</div>
          <div className='font-light'>
            {example}
          </div>
        </>}
      </div>
    );
  }
  return <div>Need help? Write: bench.support@softteco.com</div>
};

export const InfoTooltip = ({ placement, data, label, position }) => (
  <div>
    <Tooltip
      placement={placement || "bottom"}
      title={<InfoContent data={data} label={label} position={position} />}
      arrow
      color='#0F46A0'
      overlayStyle={{ maxWidth: '500px' }}
    >
      <div className='w-4 h-4'>
        <img src={AlertIcon} alt='i' />
      </div>
    </Tooltip>
  </div>
)