import React, { useEffect } from "react";
import { ToolsIFrame, ToolsPopup } from "./Styled";

export const ToolsMenu = ({ anchor, open, disableAnchor }) => {
  const iFrameUrl = `https://auth.softteco.tools/#/home?iframe=1&env=${process.env.REACT_APP_AUTHORIZE_ENV}`

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data === "linkClicked") {
        disableAnchor()
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [disableAnchor]);

  return (
    <ToolsPopup
      anchorEl={anchor}
      open={open}
      onClose={disableAnchor}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
    >
      <ToolsIFrame
        role="menu"
        name="tools"
        src={iFrameUrl}
        spellCheck="false"
      />
    </ToolsPopup>
  );
};
