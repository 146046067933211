import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextField from '../TextField';
import Checkbox from '../Checkbox';
import { countries, shortMonthYearFormat } from '../../helpers/AppHelpers';
import DatePicker from '../DatePicker';
import { Select, Space } from 'antd';
import getKeySkills from '../../services/GetKeySkills';
import { styled } from '@material-ui/core';
import { InfoTooltip } from '../Tooltips/InfoTooltip';
import AutoComplete from '../AutoComplete';

const Form = ({ item, onChange, identifier = '', errors, setErrors }) => {
  const { t } = useTranslation(['leftSidebar', 'app']);
  const [keySkillList, setKeySkillList] = useState([]);

  const getKeySkillsData = useCallback(() => {
    (async () => {
      const keySkills = await getKeySkills();
      setKeySkillList(keySkills);
    })();
  }, [setKeySkillList]);

  useEffect(() => {
    getKeySkillsData();
  }, [getKeySkillsData]);

  return (
    <div>
      <TextField
        className="mb-6"
        label={t('certifications.key.label')}
        placeholder={t('certifications.key.placeholder')}
        value={item.key}
        star="true"
        validation={errors.key}
        onChange={v => {
          onChange(`${identifier}key`, v);
          setErrors({
            key: false,
            issued: errors.issued,
            start: errors.start,
            country: errors.country
          });
        }}
        tooltip={'right'}
        tooltipType={'certifications'}
        tooltipLabel={t('certifications.key.label')}
      />

      <TextField
        className="mb-6"
        label={t('certifications.link.label')}
        placeholder={t('certifications.link.placeholder')}
        value={item.link}
        onChange={v => {
          onChange(`${identifier}link`, v);
        }}
        tooltip={'right'}
        tooltipType={'certifications'}
        tooltipLabel={t('certifications.link.label')}
      />

      <TextField
        className="mb-6"
        label={t('certifications.issued.label')}
        placeholder={t('certifications.issued.placeholder')}
        value={item.issued}
        star="true"
        validation={errors.issued}
        onChange={v => {
          onChange(`${identifier}issued`, v);
          setErrors({
            key: errors.key,
            issued: false,
            start: errors.start,
            country: errors.country
          });
        }}
        tooltip={'right'}
        tooltipType={'certifications'}
        tooltipLabel={t('certifications.issued.label')}
      />

      <div className="flex items-center gap-2 mb-6">
        <Checkbox
          checked={item.isStateAccredited}
          onChange={v => onChange(`${identifier}isStateAccredited`, v)}
        />
        <div>{t('certifications.state.label')}</div>
      </div>

      {item.isStateAccredited &&
        <AutoComplete
          label={t('certifications.state.dropdownLabel')}
          value={item.country}
          options={countries.map(country => country.value)}
          error={errors.country}
          onChange={v => {
            onChange(`${identifier}country`, v || "")
            setErrors({
              key: errors.key,
              issued: errors.issued,
              start: errors.start,
              country: false
            });
          }}
          getOptionLabel={(option) => option}
        />
      }

      <div className="grid grid-cols-2 col-gap-4 mb-6">
        <DatePicker
          name={`${t('certifications.start_date.label')}*`}
          value={item.start}
          onChange={v => {
            onChange(`${identifier}start`, v)
            setErrors({
              key: errors.key,
              issued: errors.issued,
              start: false,
              country: errors.country
            });
          }}
          clickResetDate={v => onChange(`${identifier}start`, null)}
          validationMessage={errors.start}
          views={["year", "month"]}
          format={shortMonthYearFormat}
          tooltip={'right'}
          tooltipType={'certifications'}
          tooltipLabel={t('certifications.start_date.label')}
        />
        <DatePicker
          name={t('certifications.end_date.label')}
          value={item.end}
          onChange={v => onChange(`${identifier}end`, v)}
          clickResetDate={v => onChange(`${identifier}end`, null)}
          validationMessage={errors?.end}
          views={["year", "month"]}
          format={shortMonthYearFormat}
          tooltip={'right'}
          tooltipType={'certifications'}
          tooltipLabel={t('certifications.end_date.label')}
          disableFuture={false}
        />
      </div>
      <Space size="small" direction="vertical" className="w-full mb-6">
        <label className="uppercase tracking-wide text-gray-600 text-xs font-semibold mb-2">
          <div className='flex flex-row gap-2 items-center'>
            {t('certifications.tags.label')}
            <InfoTooltip placement={'right'} data={'certifications'} label={t('certifications.tags.label')} />
          </div>
        </label>
        <CustomSelect
          className="w-full"
          mode="multiple"
          labelInValue
          allowClear
          placeholder={t('certifications.tags.placeholder')}
          options={keySkillList.map((item) => ({ value: item.title }))}
          onChange={v => onChange(`${identifier}tags`, v)}
          value={item.tags}
        />
      </Space>
    </div>
  );
};

const CustomSelect = styled(Select)({
  "& .ant-select-open": {
    border: "1px solid #000000 !important",
    display: "none !important"
  },
  "& .ant-select-selector": {
    minHeight: "42px !important",
    backgroundColor: "#EDF2F7 !important",
    border: "1px solid transparent !important",
  },
  "& .ant-select-selection-item": {
    backgroundColor: "#FFFFFF !important",
  },

});

export default Form;
