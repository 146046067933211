/* eslint-disable import/prefer-default-export */
import React from 'react';
import CopyIcon from '../../assets/images/icons/copy.png';

export function CopyButton({ textRef, onCopy }) {
  const copyText = () => {
    const textToCopy = textRef?.current?.innerText;
    if (navigator && navigator.clipboard) {
      navigator.clipboard.writeText(textToCopy);
      onCopy(textToCopy);
    }
  };

  return (
    <button type="button" onClick={copyText} className="hover:opacity-100 opacity-50">
      <img width={25} height={25} src={CopyIcon} alt="Copy text" />
    </button>
  );
}
