import React from 'react';
import { InfoTooltip } from './Tooltips/InfoTooltip';

const TextField = ({
  label,
  placeholder,
  value,
  onChange,
  className,
  classNameInput,
  disabled,
  type = 'text',
  star,
  validation,
  tooltip,
  tooltipType,
  tooltipLabel
}) => (
  <div className={`w-full flex flex-col ${className}`}>
    {label && (
      <label className={`uppercase tracking-wide ${classNameInput ? 'text-gray-400' : 'text-gray-600'} text-xs font-semibold mb-2`}>
        <div className='flex flex-row gap-2 items-center'>
          {label}{star && <span>*</span>}
          {tooltip && <InfoTooltip placement={tooltip} data={tooltipType} label={tooltipLabel} />}
        </div>
      </label>
    )}
    <input
      className={`
      appearance-none block w-full
      ${disabled ? 'text-gray-300 bg-gray-100' : 'text-gray-700 bg-gray-200'}      
      border ${!validation ? 'border-gray-200' : 'border-red-400'} 
      rounded py-3 px-4 leading-tight 
      focus:outline-none focus:bg-white focus:border-gray-500`}
      type={type}
      disabled={disabled}
      value={value}
      onChange={e => onChange(e.target.value)}
      placeholder={placeholder}
    />
  </div>
);

export default TextField;