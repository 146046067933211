import React, { useState, useEffect } from 'react';
import DeletResumeDialog from './DeletResumeDialog';
import mainIco from '../../assets/images/icons/main_icon.png';

const ItemHeadingWithDeleteButton = ({ isOpen, setOpen, item, deleteItem, activeResume, isSale }) => {
  const [dialogDeletData, setDialogDeletData] = useState({ isOpened: false });

  const onAgree = () => {
    deleteItem(item.id);
    setDialogDeletData({ isOpened: false });
  };

  const onDismiss = () => {
    setDialogDeletData({ isOpened: false });
  };
  
  // eslint-disable-next-line
  useEffect( ()=>{ setOpen(item.id === activeResume) } , [activeResume])

  return (
    <div>
      <DeletResumeDialog data={dialogDeletData} onAgree={onAgree} onDismiss={onDismiss} />
      <div id="outerDiv"
        className={`${item.isMain === true? "":" p-5"} ${item.id === activeResume ? "bg-blue-100":""} flex justify-between items-center cursor-pointer`}
        style={item.isMain=== true? { padding: '10.5px' } : {}}
        onClick={()=>setOpen(!isOpen)}
      >

        <div className="flex items-center break-all whitespace-normal w-64">
        {item.isMain ? <img className="w-10 h-10" src={mainIco} alt=''/>: null}
        <h6 className={`${item.isMain === true? "ml-1":""} text-sm font-medium`}>{item.name}</h6>
        </div>
        <div id="innerDiv" style={item.isMain === true? {minWidth: 48}:{minWidth: 40}}>
          <i className="material-icons">{isOpen ? 'expand_less' : 'expand_more'}</i>
          {(!item.isMain && !isSale) && (
            <button 
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                setDialogDeletData({ isOpened: true });
              }}
              className="text-gray-600 pl-2 hover:text-red-600 text-sm font-medium"
            >
              <div className="flex justify-end items-center">
                <i className="material-icons font-bold text-lg">close</i>
              </div>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ItemHeadingWithDeleteButton;
