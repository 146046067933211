import React, { Component } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Login from './components/Login/Login';
import { App } from './components/App/App';

import { changeLoggedIn } from './actions';
import Loading from './components/Loading/loading';
import LoginRequiredRoute from './components/Login/LoginRequiredRoute';

class Routes extends Component {
  componentDidMount() {
    const that = this;
    setTimeout(() => { that.props.changeLoggedIn(false) }, 300);
  }

  render() {
    if (this.props.loggedIn === null) {
      return <Loading />
    }
    return (
      <Switch>
        <Route exact path="/login" component={Login} />
        <LoginRequiredRoute component={App} />
        <Redirect exact from='/' to='/login' />
      </Switch>
    )
  }
}
export default withRouter(connect(
  state => ({
    loggedIn: state.loggedIn,
  }), {
  changeLoggedIn,
}
)(Routes));
