import React from 'react';
import { ImageTooltip } from './Tooltips/ImageTooltip';
import LanguageLevels from '../assets/images/english-levels-new.png'
import { InfoTooltip } from './Tooltips/InfoTooltip';

const Dropdown = ({ label, value, onChange, options, optionItem, className, error, tooltip, imageTooltip, tooltipType, tooltipLabel }) => (
  <div className={`flex flex-col mb-2 ${className}`}>
    {label && (
      <label className="uppercase tracking-wide text-gray-600 text-xs font-semibold mb-2">
        <div className='flex items-center flex-row gap-2'>
          {label}
          {imageTooltip && <ImageTooltip placement={imageTooltip} imgSrc={LanguageLevels} />}
          {tooltip && <InfoTooltip placement={tooltip} data={tooltipType} label={tooltipLabel} />}
        </div>
      </label>
    )}
    <div
      className={`inline-flex relative w-full bg-gray-200 text-gray-800 rounded leading-tight focus:outline-none
      border ${error ? 'border-red-400' : 'border-gray-200'}`}
    >
      <select
        className="block appearance-none w-full bg-gray-200 text-gray-800 py-3 px-4 rounded focus:outline-none"
        value={value}
        onChange={(e) => onChange(e.target.value)}
      >
        {options.map(optionItem)}
      </select>
      <div className="pointer-events-none absolute inset-y-0 right-0 flex justify-center items-center px-2 bg-gray-200">
        <i className="material-icons">expand_more</i>
      </div>
    </div>
  </div >
);

export default Dropdown;
