import React from 'react';
import { useTranslation } from 'react-i18next';

export const TabDescription = ({ tab, example = true }) => {
  const { t } = useTranslation('leftSidebar');
  const text = t(`${tab}.tab_description.text`);
  return (
    <>
      <hr className="my-6" />
      <div className="text-sm text-gray-600">{text}</div>
      {example && (
        <div className="my-6 text-sm text-gray-600">
          <div className="font-semibold">Example:</div>
          <div>{t(`${tab}.tab_description.example`)}</div>
        </div>
      )}
      <hr className="my-6" />
    </>
  );
};
