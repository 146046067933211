import React from 'react';
import { Select, Space } from 'antd';
import { searchParamList } from '../../../../../helpers/AppHelpers';

const PositionFilter = (props) => {
  const { searchItems, positionList, setPosition } = props;
  const handlePositionChange = (item) => {
    setPosition({ searchParam: 'position', value: item });
  };

  return (
    <>
      {searchItems.includes(searchParamList[1].value) ? (
        <Space size="small" direction="vertical" className="w-full">
          <label>{searchParamList[1].label}</label>
          <Select
            allowClear
            placeholder={searchParamList[1].placeholder}
            options={positionList}
            onChange={handlePositionChange}
            style={{ width: '100%' }}
          />
        </Space>
      ) : null}
    </>
  );
};

export default PositionFilter;
