import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import styles from '../PreSaveDialog.module.css';

export const ConfirmProjectName = ({ isOpen, setIsOpen, onChange, identifier }) => {
  const handleClose = () => {
    setIsOpen(false);
  };

  const confirmShowProject = () => {
    onChange(`${identifier}isShowName`, true);
    handleClose();
  };

  return (
    <Dialog
      open={isOpen}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{ style: { width: '500px' } }}
    >
      <div style={{ padding: '30px 30px 0 30px' }}>
        <DialogTitle id="alert-dialog-slide-title" style={{ padding: 0 }}>
          <div style={{ fontWeight: '600' }}>Are you sure?</div>
        </DialogTitle>
        <div style={{ paddingBottom: '20px', paddingTop: '5px' }}>
          Please confirm that you have a permission from the account manager or past employer to
          showcase the client/project name in your CV 
        </div>
        <DialogActions
          classes={{
            root: styles.popupControls,
          }}
        >
          <Button aria-controls="simple-menu" aria-haspopup="true" onClick={confirmShowProject}>
            Yes
          </Button>
          <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};
