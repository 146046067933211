import React from 'react';
import { Space } from 'antd';
import { searchParamList } from '../../../../../helpers/AppHelpers';
import TagSelect from '../../../../../shared/TagSelect/TagSelect';

const TechSkillsFilter = (props) => {
  const { searchItems, setTechSkills } = props;
  const handleTechSkillsChange = (item) => {
    setTechSkills({ searchParam: 'techSkills', value: item });
  };

  return (
    <>
      {searchItems.includes(searchParamList[2].value) ? (
        <Space size="small" direction="vertical" className="w-full">
          <label>{searchParamList[2].label}</label>
          <TagSelect
            placeholder={searchParamList[2].placeholder}
            onChange={handleTechSkillsChange}
          />
        </Space>
      ) : null}
    </>
  );
};

export default TechSkillsFilter;
