import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import set from 'lodash/set';
import { addItem } from '../../utils';
import ItemHeading from '../ItemHeading';
import AddItemButton from '../AddItemButton';
import Form from './Form';

const AddItem = ({ heading, dispatch }) => {
  const [isOpen, setOpen] = useState(false);
  const [errors, setErrors] = useState({
    key: false,
    issued: false,
    start: false,
    country: false
  });
  const [item, setItem] = useState({
    id: uuidv4(),
    enable: true,
    key: '',
    link: '',
    isStateAccredited: false,
    country: '',
    start: null,
    end: null,
    issued: '',
    tags: []
  });

  const onChange = (key, value) => setItem(set({ ...item }, key, value));

  const onSubmit = () => {
    console.log(item.country)
    console.log(String(item.country))
    if (item.key === '' || item.issued === '' || item.start === null || (item.isStateAccredited === true && String(item.country) === '')) {
      setErrors({
        key: item.key === '',
        issued: item.issued === '',
        start: item.start === null,
        country: item.isStateAccredited === true ? item.country === '' : false
      });
      return;
    }

    addItem(dispatch, 'certifications', item);

    setItem({
      id: uuidv4(),
      enable: true,
      key: '',
      link: '',
      isStateAccredited: false,
      country: '',
      start: null,
      end: null,
      issued: '',
      tags: []
    });

    setOpen(false);
  };

  return (
    <div className="my-4 border border-gray-200 rounded">
      <ItemHeading heading={heading} setOpen={setOpen} isOpen={isOpen} />

      <div className={`${isOpen ? 'block' : 'hidden'} px-5 pb-5`}>
        <Form item={item} onChange={onChange} errors={errors} setErrors={setErrors} />

        <AddItemButton onSubmit={onSubmit} />
      </div>
    </div>
  );
};

export default AddItem;
