import config from '../config';
import { getElementFromStorage } from '../utils/storage';

export const getKeySkills = (pos) => {
  const reqHeaders = new Headers();
  const token = getElementFromStorage('jwtToken');
  reqHeaders.append('Authorization', `Bearer ${token}`);
  const BaseURL = `${config.http.BASE_URL}/key-skills${pos ? `_${pos}` : ''}`;
  return fetch(BaseURL, { method: 'GET', headers: reqHeaders })
    .then(response => (response.status !== 200 ? ({
      error: true,
    }) : response.json().then(json => json)
      .catch(() => ({
        error: true,
      }))), (error) => {
        throw new Error(error.message);
      });
};

export default getKeySkills;
