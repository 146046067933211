import config from '../config';
import { getElementFromStorage } from '../utils/storage';

let currentAbortControllerForUsers = null;

export const GetSearchResultsByUsers = (ownUserInfo, str) => {
  if (currentAbortControllerForUsers) {
    currentAbortControllerForUsers.abort();
  }

  currentAbortControllerForUsers = new AbortController();

  const BaseURL = `${config.http.BASE_URL}/users${str ? `?${str}` : "?_limit=900"}`;
  const token = getElementFromStorage('jwtToken');

  return fetch(BaseURL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token === 'undefined' ? '' : token}`,
      profileid: ownUserInfo.userId
    },
    signal: currentAbortControllerForUsers.signal,
  }).then(
    (response) =>
      response.status !== 200
        ? {
          error: true,
          responseStatus: response.status,
        }
        : response
          .json()
          .then((json) => json)
          .catch(() => ({
            error: true,
          })),
    (error) => {
      if (error.name === 'AbortError') {
        return { aborted: true };
      } else {
        throw new Error(error.message);
      }
    },
  );
};

export default GetSearchResultsByUsers;