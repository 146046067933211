import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const Loading = () => {

  return (
    <div className="flex justify-center items-center h-screen flex-col">
      <div>
        <CircularProgress size={50} thickness={2} />
      </div>
      {/* <div className="text-center">
        <p>Please, wait a moment!</p>
      </div> */}
    </div>
  );
};
export default Loading;
