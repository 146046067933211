/* eslint-disable import/prefer-default-export, consistent-return */
import { useState } from 'react';

export function useBeautifier(apiFunc, getInputFunc) {
  const [data, setData] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const clearError = () => {
    setError(null);
  };

  function beautify() {
    try {
      setLoading(true);
      const input = getInputFunc();
      return apiFunc(input)
        .then(({ code, message, data: response }) => {
          if (code === 'OK') {
            setError(null);
            setData(response);
          } else {
            setError(message);
            setData(null);
          }
          setLoading(false);
        })
        .catch(err => {
          setError(err.message);
          setLoading(false);
        });
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  }

  return { beautify, data, error, clearError, isLoading };
}
