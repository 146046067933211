import React from 'react';
import { Select, Space } from 'antd';

const OptionFilter = (props) => {
  const { options, placeholder, setSearchItems } = props;
  const handleMultiSelectChange = (selectedOptions) => {
    const value = selectedOptions.map((item) => item.value);
    setSearchItems(value);
  };

  return (
    <>
      <Space size="small" direction="vertical" className="w-full">
        <label>Filter Options</label>
        <Select
          className="w-full"
          mode="multiple"
          labelInValue
          allowClear
          placeholder={placeholder}
          options={options}
          onChange={handleMultiSelectChange}
        />
      </Space>
    </>
  );
};

export default OptionFilter;
