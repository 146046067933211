import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { get } from 'lodash';
import { addItem } from '../../utils';
import { getKeySkills } from '../../services/GetKeySkills';
import AutoComplete from '../AutoComplete';

const AddItem = ({ dispatch, keySkills }) => {
  const cleanItem = {
    id: uuidv4(),
    enable: true,
    value: '',
  };

  const [item, setItem] = useState(cleanItem);

  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [noOptions, setNoOptions] = useState(false);

  const add = () => {
    if (item.value === '') {
      return;
    }
    addItem(dispatch, 'keySkills', item);
    setItem(cleanItem);
  };

  useEffect(() => {
    (async () => {
      const res = await getKeySkills('key');
      setOptions(res);
    })();
    setNoOptions(false);
  }, []);

  const autocompleteOptions = options.filter(
    value => !keySkills?.find(x => value.title.toLowerCase() === x.title.toLowerCase())
  )

  return (
    <div className="mb-5">
      <div className="grid grid-cols-4 gap-4">
        <div className="col-span-3">
          <AutoComplete
            value={item}
            open={open}
            onOpen={() => setOpen(!open)}
            onClose={() => setOpen(!open)}
            options={autocompleteOptions}
            onChange={(v) => setItem(v)}
            placeholder=""
            getOptionLabel={(option) => get(option, 'title') || ''}
          />
        </div>

        <div className="mb-2 flex justify-center items-center">
          <button
            type="button"
            onClick={add}
            className="w-full h-40px col-span-1 bg-gray-600 hover:bg-gray-700 cursor-pointer text-sm font-medium rounded"
          >
            <div className="flex justify-center items-center">
              <i className="material-icons font-bold text-white text-lg">add</i>
            </div>
          </button>
        </div>
      </div>
      {noOptions && (
        <label className="text-sm text-gray-600 ml-2 select-none">
          Select Position to See the List
        </label>
      )}
    </div>
  );
};

export default AddItem;
