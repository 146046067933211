export const tooltypesTexts = {
  'summary': {
    "First Name": {
      instruction: "Write your first name. We recommend to adapt it to English - see how on the Intranet.",
      example: "Alex"
    },
    "Last Name": {
      instruction: "Write your last name. We recommend to adapt it to English - see how on the Intranet.",
      example: "Alekseev"
    },
    "Position": {
      instruction: "Select your position from the drop-down menu. Note: technical skills will automatically be selected based on the stated position. Make sure to review them and ensure they are relevant.",
      example: "Frontend Developer"
    },
    "Position Level": {
      instruction: "Select your position level from the drop-down menu in accordance with your experience",
      example: "Middle"
    },
    "Years of Experience": {
      instruction: "Select your years of experience ",
      example: "4"
    },
    "Employee’s description": {
      instruction: "Briefly describe your technical and soft skills, working experience in years, key technologies that you work with. Make sure to be concise and convincing. Try keeping the description within 230 - 550 characters. Note: write in third person, without using name and pronounce.",
      example: {
        default: "DEFAULT",
        "Frontend Developer": "A skilled full-stack developer with over 5 years of experience. Has experience with various tools and technologies and strives to constantly improve his skills. Is especially proficient in Docker, Visual Code Studio, and NPM. Is a great team player and has excellent communication skills. Is always readyto help his team members and is a quick learner.",
        "Java Developer": "A skilled full-stack developer with over 5 years of experience. Has experience with various tools and technologies and strives to constantly improve his skills. Is especially proficient in Docker, Visual Code Studio, and NPM. Is a great team player and has excellent communication skills. Is always readyto help his team members and is a quick learner.",
        "QA": "A skilled full-stack developer with over 5 years of experience. Has experience with various tools and technologies and strives to constantly improve his skills. Is especially proficient in Docker, Visual Code Studio, and NPM. Is a great team player and has excellent communication skills. Is always readyto help his team members and is a quick learner.",
        "Project Manager": "A skilled full-stack developer with over 5 years of experience. Has experience with various tools and technologies and strives to constantly improve his skills. Is especially proficient in Docker, Visual Code Studio, and NPM. Is a great team player and has excellent communication skills. Is always readyto help his team members and is a quick learner.",
        ".Net Developer": "NET",
        "Android Developer": "Android",
        "Business Analyst": "Bus. anal",
        "C++ Developer": "c++ dev",
        "Cross-Platform Mobile Developer": "cros plat",
        "Data Engineer": "data eng",
        "Data Scientist": "data scie",
        "DevOps Engineer": "devop",
        "Fullstack Developer": "fulstak",
        "iOS Developer": "ios",
        "ML/AI Engineer": "ml al",
        "PHP Developer": "php",
        "Python Developer": "p[yhon",
        "QA Engineer": "quai",
        "Ruby Developer": "rubi",
        "Salesforce Developer": "slaeseforece",
      }
    }
  },
  'education': {
    "Name": {
      instruction: "Type the name of the educational institution",
      example: "Belarusian State University"
    },
    "Major": {
      instruction: "Specify your Major (degree)",
      example: "Masters in Computer Science"
    },
    "Description": {
      instruction: "Describe the core courses that you took in the institution and the main area of knowledge. Note that it should be relevant to your position.",
    },
  },
  'certifications': {
    "Name of certificate": {
      instruction: "Please write the name of your certificate",
      example: "AWS Certified Cloud Practicioner"
    },
    "Add a credential/file link": {
      instruction: "Add the link to your certificate, if available",
    },
    "Issued BY": {
      instruction: "Please name the organizaiton that issued your certificate",
      example: "Amazon Web Services"
    },
    "Issue date": {
      instruction: "Select the issue date of your certificate",
    },
    "Expiration date": {
      instruction: "Select the expiration date of your certificate",
    },
    "Tags": {
      instruction: "Select appropriate tags for your certificate",
      example: "Project Management, Scrum"
    },
  },
  'programmingLanguages': {
    "autoComplete": {
      instruction: "Select the programming language",
      example: "JavaScript"
    },
    "Name": {
      instruction: "Start typing the name of the programming language to add it",
      example: "JavaScript"
    },
    "Years": {
      instruction: "Type years of experience with the selected language",
      example: "4"
    },
    "Level": {
      instruction: "Select your level of proficiency with the programming language",
      example: "Middle"
    },
  },
  'dataBases': {
    "autoComplete": {
      instruction: "Select the database ",
      example: "MongoDB"
    },
    "Name": {
      instruction: "Start typing the name of the database to add it",
      example: "MongoDB"
    },
    "Years": {
      instruction: "Type years of experience with the selected database",
      example: "4"
    },
    "Level": {
      instruction: "Select your level of proficiency with the database",
      example: "Advanced"
    },
  },
  'ideOrTools': {
    "autoComplete": {
      instruction: "Select the IDE or the development tool",
      example: "Postman"
    },
    "Name": {
      instruction: "Please type the name of the IDE or the development tool",
      example: "Postman"
    },
    "Years": {
      instruction: "Type years of experience with the selected IDE or the development tool",
      example: "4"
    },
    "Level": {
      instruction: "Select your level of proficiency with the IDE or the development tool",
      example: "Advanced"
    },
  },
  'developmentTechnologies': {
    "autoComplete": {
      instruction: "Select the development technology",
      example: "Redux"
    },
    "Name": {
      instruction: "Start typing the name of the development technology to add it",
      example: "Redux"
    },
    "Years": {
      instruction: "Type years of experience with the selected development technology",
      example: "4"
    },
    "Level": {
      instruction: "Select your level of proficiency with the development technology",
      example: "Advanced"
    },
  },
  'ciCdTools': {
    "autoComplete": {
      instruction: "Select the CI/CD tool",
      example: "Ansible"
    },
    "Name": {
      instruction: "Please type the name of the CI/CD tool",
      example: "Ansible"
    },
    "Years": {
      instruction: "Please type your years of experience with the selected CI/CD tool",
      example: "4"
    },
    "Level": {
      instruction: "Select your level of proficiency with the CI/CD tool",
      example: "Middle"
    },
  },
  'versionControlSystems': {
    "autoComplete": {
      instruction: "Select the technology ",
      example: "GitHub"
    },
    "Name": {
      instruction: "Start typing the name of the technology to add it",
      example: "GitHub"
    },
    "Years": {
      instruction: "Please type your years of experience with the selected technology",
      example: "4"
    },
    "Level": {
      instruction: "Select your level of proficiency with the technology",
      example: "Advanced"
    },
  },
  'iOsTechnologies': {
    "autoComplete": {
      instruction: "Select the iOS technology",
      example: "SwiftUI"
    },
    "Name": {
      instruction: "Start typing the name of the iOS technology to add it",
      example: "SwiftUI"
    },
    "Years": {
      instruction: "Please type your years of experience with the selected iOS technology",
      example: "4"
    },
    "Level": {
      instruction: "Select your level of proficiency with the iOS technology",
      example: "Advanced"
    },
  },
  'androidTechnologies': {
    "autoComplete": {
      instruction: "Select the Android technology",
      example: "Mapbox"
    },
    "Name": {
      instruction: "Start typing the name of the Android technology to add it",
      example: "Mapbox"
    },
    "Years": {
      instruction: "Please type your years of experience with the selected Android technology",
      example: "4"
    },
    "Level": {
      instruction: "Select your level of proficiency with the Android technology",
      example: "Middle"
    },
  },
  'developmentMethodologies': {
    "autoComplete": {
      instruction: "Select the development methodology",
      example: "Scrum"
    },
    "Name": {
      instruction: "Please type the name of the  development methodology",
      example: "Scrum"
    },
    "Years": {
      instruction: "Please type your years of experience with the selected development methodology",
      example: "4"
    },
    "Level": {
      instruction: "Select your level of proficiency with the  development methodology",
      example: "Advanced"
    },
  },
  "other": {
    "name": {
      instruction: "Please type the name of the skill",
      example: "Team management"
    },
    "years": {
      instruction: "Please type your years of experience with the specified skill",
      example: "4"
    },
    "level": {
      instruction: "Select your level of proficiency with the skill",
      example: "Advanced"
    }
  },
  "projects": {
    "Short Description": {
      instruction: "Briefly describe what the project is about and what industry it belongs to",
      example: "An e-learning app for children"
    },
    "Project Name": {
      instruction: 'Please provide the project name <br/> Note: check with the manager if the project is under NDA or not. If the project name is allowed to be shown, tick the "show project name" box',
      example: "LearnFast"
    },
    "Project Description": {
      instruction: "Briefly describe the project: highlight its main purpose, challenges that it resolves, core functionality, and value for users. Do not go into too many details and try focusing on the main selling points.",
      example: "The application is aimed at helping children learn the alphabet in an easy and fun manner. The app has various gamification elements for better engagement, offers audio and video chat options and runs on both iOS and Android platforms."
    },
    "Role": {
      instruction: "Specify your role on the project",
      example: "QA engineer"
    },
    "Application type": {
      instruction: "Specify the application type",
      example: "Mobile"
    },
    "Tasks Executed": {
      instruction: "Please list up to 7 most impactful tasks that you have executed on the project. Separate each task with a comma. Make sure to be concise and avoid redundancy. <br/> Note: tasks have to be role-specific! ",
      example: "Optimization of the app's performance; Team management; Creation of project documentation; Implementation of improvements"
    },
    "Tools and Technologies": {
      instruction: "List used tools and technologies, separate them with a comma.",
    }
  }
}