import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import styles from '../Dialog.module.css';

export default function DeletResumeDialog({ data, onAgree, onDismiss }) {
  return (
    <Dialog
      open={data.isOpened}
      keepMounted
      onClose={onDismiss}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <p>Do you want to delete this CV?</p>
          <p>All changes will be deleted irretrievably</p>
        </div>
      </DialogTitle>
      <DialogActions
        classes={{
          root: styles.popupControls,
        }}
      >
        <Button aria-controls="simple-menu" aria-haspopup="true" onClick={onAgree}>
          Yes
        </Button>
        <Button aria-controls="simple-menu" aria-haspopup="true" onClick={onDismiss}>
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
}
