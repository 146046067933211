/* eslint-disable new-cap */
import html2canvas from 'html2canvas';
import * as jsPDF from 'jspdf';

const moveBy = (array, element, delta) => {
  const index = array.findIndex(item => item.id === element.id);
  const newIndex = index + delta;
  if (newIndex < 0 || newIndex === array.length) return;

  const buf = array[index];
  array.splice(index, 1);
  array.splice(newIndex, 0, buf);
};

const hexToRgb = hex => {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
    }
    : null;
};

const copyToClipboard = text => {
  const textArea = document.createElement('textarea');
  textArea.style.position = 'fixed';
  textArea.style.top = 0;
  textArea.style.left = 0;
  textArea.style.width = '2em';
  textArea.style.height = '2em';
  textArea.style.padding = 0;
  textArea.style.border = 'none';
  textArea.style.outline = 'none';
  textArea.style.boxShadow = 'none';
  textArea.style.background = 'transparent';
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  const successful = document.execCommand('copy');
  document.body.removeChild(textArea);
  return successful;
};

const addItem = (dispatch, key, value) => {
  dispatch({
    type: 'add_item',
    payload: {
      key,
      value,
    },
  });
};

const unshiftItem = (dispatch, key, value) => {
  dispatch({
    type: 'add_item_unshift',
    payload: {
      key,
      value,
    },
  });
};

const deleteItem = (dispatch, key, value) => {
  dispatch({
    type: 'delete_item',
    payload: {
      key,
      value,
    },
  });
};

const moveItemUp = (dispatch, key, value) => {
  dispatch({
    type: 'move_item_up',
    payload: {
      key,
      value,
    },
  });
};

const moveItemDown = (dispatch, key, value) => {
  dispatch({
    type: 'move_item_down',
    payload: {
      key,
      value,
    },
  });
};

const moveItemBy = (dispatch, key, value, move) => {
  dispatch({
    type: 'move_item_by',
    payload: {
      key,
      value,
      move,
    },
  });
};


const importData = (dispatch, payload) => {
  dispatch({
    type: 'import_data',
    payload,
  });
};

const isAdmin = (dispatch, payload) => {
  dispatch({
    type: 'isAdmin',
    payload,
  });
};

const isSale = (dispatch, payload) => {
  dispatch({
    type: 'isSale',
    payload,
  });
};

const setOwnCvId = (dispatch, payload) => {
  dispatch({
    type: 'setOwnCvId',
    payload,
  });
};

const setOwnUserInfo = (dispatch, payload) => {
  dispatch({
    type: 'setOwnUserInfo',
    payload,
  });
};

const setSelectedCvId = (dispatch, payload) => {
  dispatch({
    type: 'setSelectedCvId',
    payload,
  });
};

const setSelectedUserId = (dispatch, payload) => {
  dispatch({
    type: 'setSelectedUserId',
    payload,
  });
};

const changeSpinnerValue = (dispatch, payload) => {
  dispatch({
    type: 'change_spinner_value',
    payload,
  });
};

const importJson = (event, dispatch) => {
  const fr = new FileReader();
  fr.addEventListener('load', () => {
    const importedObject = JSON.parse(fr.result);
    dispatch({ type: 'import_data', payload: importedObject });
  });
  fr.readAsText(event.target.files[0]);
};

const saveAsPdf = (pageRef, panZoomRef, quality, type, firstName, lastName, position) =>
  new Promise(resolve => {
    panZoomRef.current.autoCenter(1);
    panZoomRef.current.reset();

    setTimeout(() => {
      html2canvas(pageRef.current, {
        scale: 5,
        useCORS: true,
        allowTaint: true,
      }).then(canvas => {
        const image = canvas.toDataURL('image/jpeg', quality / 100);
        const doc = new jsPDF({
          orientation: 'portrait',
          unit: 'px',
          format: type === 'unconstrained' ? [canvas.width, canvas.height] : 'a4',
        });

        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();

        const widthRatio = pageWidth / canvas.width;
        const heightRatio = pageHeight / canvas.height;
        const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

        const canvasWidth = canvas.width * ratio;
        const canvasHeight = canvas.height * ratio;

        let marginX = 0;
        let marginY = 0;

        if (type !== 'unconstrained') {
          marginX = (pageWidth - canvasWidth) / 2;
          marginY = (pageHeight - canvasHeight) / 2;
        }

        doc.addImage(image, 'JPEG', marginX, marginY, canvasWidth, canvasHeight, null, 'SLOW');
        doc.save(`CV${firstName ? ` - ${firstName}` : ''}${lastName ? ` ${lastName}` : ''}${position ? ` (${position})` : ''}.pdf`);
        resolve();
      });
    }, 250);
  });

const saveAsMultiPagePdf = (pageRef, panZoomRef, firstName, lastName, userPosition) =>
  new Promise(resolve => {
    panZoomRef.current.autoCenter(1);
    panZoomRef.current.reset();
    setTimeout(() => {
      html2canvas(pageRef.current).then(canvas => {
        var imgWidth = 210;
        var pageHeight = 290;
        var imgHeight = canvas.height * imgWidth / canvas.width;
        var heightLeft = imgHeight;

        var doc = new jsPDF('p', 'mm');
        var position = 0;
        var pageData = canvas.toDataURL('image/jpeg', 1.0);
        var imgData = encodeURIComponent(pageData);
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        doc.setLineWidth(5);
        doc.setDrawColor(255, 255, 255);
        doc.rect(0, 0, 210, 295);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
          doc.setLineWidth(5);
          doc.setDrawColor(255, 255, 255);
          doc.rect(0, 0, 210, 295);
          heightLeft -= pageHeight;
        }
        doc.save(`CV${firstName ? ` - ${firstName}` : ''}${lastName ? ` ${lastName}` : ''}${userPosition ? ` (${userPosition})` : ''}.pdf`);
        resolve();
      });
    }, 250);
  });


const escapeRegExp = (string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

const replaceText = (htmlContent, translations) => {
  const blockedContent = {};
  let placeholderIndex = 0;

  htmlContent = htmlContent.replace(/<div class="text-sm description">(.*?)<\/div>/gs, (match) => {
    blockedContent[`__blocked_${placeholderIndex}__`] = match;
    return `__blocked_${placeholderIndex++}__`;
  });

  htmlContent = htmlContent.replace(/<div class="mb-3 education-item">(.*?)<\/div>/gs, (match) => {
    blockedContent[`__blocked_${placeholderIndex}__`] = match;
    return `__blocked_${placeholderIndex++}__`;
  });

  for (const [key, value] of Object.entries(translations)) {
    const escapedKey = escapeRegExp(key.trim());
    const regex = new RegExp(`\\b${escapedKey}(?=\\W|$)`, "gi");
    htmlContent = htmlContent.replace(regex, value);
  }

  for (const [placeholder, originalHtml] of Object.entries(blockedContent)) {
    htmlContent = htmlContent.replace(placeholder, originalHtml);
  }

  return htmlContent;
};

const saveAsMultiPageRusPdf = (pageRef, firstName, lastName, userPosition) =>
  new Promise(resolve => {
    const htmlElement = pageRef.current;

    if (htmlElement) {
      const clonedElement = htmlElement.cloneNode(true);

      let htmlContent = clonedElement.innerHTML;
      htmlContent = replaceText(htmlContent, translationsForPdf);
      clonedElement.innerHTML = htmlContent;

      const temporaryContainer = document.createElement('div');
      temporaryContainer.style.position = 'absolute';
      temporaryContainer.style.top = '-9999px';
      temporaryContainer.appendChild(clonedElement);
      document.body.appendChild(temporaryContainer);

      setTimeout(() => {
        html2canvas(clonedElement).then(canvas => {
          var imgWidth = 210;
          var pageHeight = 290;
          var imgHeight = canvas.height * imgWidth / canvas.width;
          var heightLeft = imgHeight;

          var doc = new jsPDF('p', 'mm');
          var position = 0;
          var pageData = canvas.toDataURL('image/jpeg', 1.0);
          var imgData = encodeURIComponent(pageData);
          doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
          doc.setLineWidth(5);
          doc.setDrawColor(255, 255, 255);
          doc.rect(0, 0, 210, 295);
          heightLeft -= pageHeight;

          while (heightLeft >= 0) {
            position = heightLeft - imgHeight;
            doc.addPage();
            doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            doc.setLineWidth(5);
            doc.setDrawColor(255, 255, 255);
            doc.rect(0, 0, 210, 295);
            heightLeft -= pageHeight;
          }

          doc.save(`РЕЗЮМЕ${firstName ? ` - ${firstName}` : ''}${lastName ? ` ${lastName}` : ''}${userPosition ? ` (${userPosition})` : ''}.pdf`);
          resolve();
          document.body.removeChild(temporaryContainer);
        });
      }, 250);
    }
  });

const setResumeItems = (dispatch, payload) => {
  dispatch({
    type: 'setResumeItems',
    payload,
  });
};

const setActiveResume = (dispatch, payload) => {
  dispatch({
    type: 'setActiveResume',
    payload,
  });
};

export const translationsForPdf = {
  "Technical skills": "Технические навыки",
  "Projects Role": "Роль на проекте",
  "Application Type": "Тип приложения",
  "Responsibilities": "Обязанности",
  "Tools, Technologies": "Инструменты, технологии",
  "Programming Lang.": "Язык программирования",
  "Databases": "Базы данных",
  "IDE/Development Tools": "IDE / Инструменты разработки",
  "Development Technologies": "Технологии разработки",
  "CI/CD Tools": "CI/CD инструменты",
  "Issue Tracking / Version Control System": "Система контроля версий",
  "iOS Technologies": "Технологии iOS",
  "Android Technologies": "Технологии Android",
  "Development Methodologies": "Методологии разработки",
  "Other Skills": "Другие навыки",
  "Experience": "Опыт",
  "Level": "Уровень",
  "Languages": "Языки",
  "Education": "Образование",
  "Key skills": "Основные навыки",
  "Skills": "Навыки",
  "Projects": "Проекты",
  "NET Developer": "NET разработчик",
  "Account Manager": "Специалист по работе с клиентами",
  "Accountant": "Бухгалтер",
  "Android Developer": "Android разработчик",
  "Business Analyst": "Бизнес-аналитик",
  "C++ Developer": "C++ разработчик",
  "Chief Accountant": "Главный бухгалтер",
  "Cleaner": "Уборщик помещений (производственных, служебных) 1 разряда",
  "Copywriter": "Технический писатель",
  "Counsel": "Юрисконсульт",
  "Data Analyst": "Специалист по анализу данных и машинному обучению",
  "Data Engineer": "Дата-инженер",
  "Data Scientist": "Специалист по анализу данных и машинному обучению",
  "Delivery Manager": "Менеджер по информационным технологиям",
  "Deputy Director": "Заместитель директора",
  "Deputy Head": "Заместитель начальника отдела",
  "Designer": "Дизайнер",
  "DevOps Engineer": "Инженер DevOps",
  "Director": "Директор",
  "English Teacher": "Преподаватель английского языка",
  "Financial Analyst": "Ведущий экономист",
  "Flutter Developer": "Flutter разработчик",
  "Frontend Developer": "Фронтенд разработчик",
  "Golang developer": "Golang разработчик",
  "Graphic Designer": "Графический дизайнер",
  "HR Manager": "Специалист по персоналу",
  "HR Specialist": "Специалист по кадрам",
  "Head": "Начальник отдела",
  "JS Fullstack Developer": "JS фулстек разработчик",
  "Java Developer": "Java разработчик",
  "Lead Counsel": "Ведущий юрист",
  "Lead Legal Adviser": "Ведущий юрисконсульт",
  "Machine Learning Engineer": "Специалист по анализу данных и машинному обучению",
  "Marketing Manager": "Менеджер по маркетингу",
  "Marketing Specialist": "Специалист по маркетингу",
  "NodeJS Developer": "NodeJS разработчик",
  "Office Manager": "Офис-менеджер",
  "Other": "Другое",
  "PHP Developer": "PHP разработчик",
  "PR Manager": "Специалист по связям с общественностью",
  "Product Owner": "Продакт оунер",
  "Project Manager": "Проектный менеджер",
  "Python Backend Developer": "Python бэкенд разработчик",
  "Python Developer": "Python разработчик",
  "QA Engineer": "Тестировщик программного обеспечения",
  "React Native Developer": "React Native разработчик",
  "Recruiter": "Специалист по подбору персонала",
  "Recruiter Assistant": "Помощник специалиста по подбору персонала",
  "Ruby Developer": "Ruby разработчик",
  "SEO Specialist": "SEO специалист",
  "Safety Engineer": "Инженер по охране труда",
  "Sales Assistant": "Помощник специалиста по продаже",
  "Sales Manager": "Специалист по продаже",
  "Salesforce Developer": "Salesforce разработчик",
  "Solution Architect": "Архитектор решений",
  "System Administrator": "Системный администратор",
  "UI/UX Designer": "UX/UI дизайнер",
  "iOS Developer": "iOS разработчик",
  "Junior": "Джуниор",
  "Middle": "Миддл",
  "Senior": "Сениор",
  "Lead": "Лид",
  "Limited Working Proficiency": "Ограниченный",
  "Professional Working Proficiency": "Высокий",
  "Full Professional Proficiency": "Продвинутый",
  "Native": "Профессиональный",
  "Basic": "Начальный",
  "Intermediate": "Средний",
  "Advanced": "Высокий",
  "Expert": "Продвинутый"
};

export {
  hexToRgb,
  copyToClipboard,
  addItem,
  unshiftItem,
  deleteItem,
  moveItemUp,
  moveItemDown,
  importData,
  isAdmin,
  isSale,
  changeSpinnerValue,
  importJson,
  saveAsPdf,
  saveAsMultiPagePdf,
  saveAsMultiPageRusPdf,
  setOwnCvId,
  setOwnUserInfo,
  setSelectedCvId,
  setSelectedUserId,
  moveItemBy,
  moveBy,
  setResumeItems,
  setActiveResume
};
