import React from 'react';
import { Select, Space } from 'antd';
import { searchParamList, isFired } from '../../../../../helpers/AppHelpers';

const IsFireFilter = (props) => {
  const { searchItems, setIsFired } = props;
  const handleisFirefChange = (value) => {
    setIsFired(value);
  };

  return (
    <>
      {searchItems.includes(searchParamList[6].value) ? (
        <Space size="small" direction="vertical" className="w-full">
          <label>{searchParamList[6].label}</label>
          <Select
            className="w-full"
            allowClear
            placeholder={searchParamList[6].placeholder}
            options={isFired}
            onChange={handleisFirefChange}
          />
        </Space>
      ) : null}
    </>
  );
};

export default IsFireFilter;
