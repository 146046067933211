import React from 'react';
import { Select, Space } from 'antd';
import { searchParamList } from '../../../../../helpers/AppHelpers';

const KeySkillsFilter = (props) => {
  const { searchItems, keySkillList, setKeySkills } = props;
  const handleKeySkillsChange = (item) => {
    const values = item.map((i) => i.key);
    setKeySkills({ searchParam: 'keySkills', value: values });
  };

  const handleEnterClick = (event) => {
    if (event.key === 'Enter') {
      setKeySkills({ searchParam: 'keySkills', value: event.target.value });
    }
  }

  return (
    <>
      {searchItems.includes(searchParamList[4].value) ? (
        <Space size="small" direction="vertical" className="w-full">
          <label>{searchParamList[4].label}</label>
          <Select
            className="w-full"
            mode="multiple"
            labelInValue
            allowClear
            placeholder={searchParamList[4].placeholder}
            options={keySkillList.map((item) => ({ value: item.title }))}
            onChange={handleKeySkillsChange}
            onKeyUp={handleEnterClick}
          />
        </Space>
      ) : null}
    </>
  );
};

export default KeySkillsFilter;
