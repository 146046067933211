/* eslint-disable import/prefer-default-export */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { errorToast, infoToast } from '../toast';
import { CopyButton } from './CopyButton';

export function InputBeautifier({ children, beautifier }) {
  const { t } = useTranslation();
  const textRef = useRef(null);
  const isAPIEnable =
    process.env.REACT_APP_BEAUTIFIER_API_KEY && process.env.REACT_APP_BEAUTIFIER_API_URL;

  const { beautify, data, error, clearError, isLoading } = beautifier;

  useEffect(() => {
    if (error) {
      errorToast(error);
      clearError();
    }
  }, [error, clearError]);

  if (!isAPIEnable) {
    return children;
  }

  const handleCopyText = () => {
    infoToast(t('buttons.copy.successToast'));
  };

  return (
    <>
      {children}
      <div className="flex justify-between">
        <button
          type="button"
          onClick={beautify}
          disabled={isLoading}
          className="my-4 bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow"
        >
          {isLoading ? t('buttons.beautify.loading') : t('buttons.beautify.label')}
        </button>
        {data && <CopyButton textRef={textRef} onCopy={handleCopyText} />}
      </div>
      {data && (
        <div
          ref={textRef}
          className="whitespace-pre-line	border border-[#3F51B5] border-indigo-600 rounded py-3 px-4 text-sm text-gray-600"
        >
          {data}
        </div>
      )}
    </>
  );
}

InputBeautifier.propTypes = {
  beautifier: PropTypes.shape({
    beautify: PropTypes.func,
    data: PropTypes.string,
    error: PropTypes.string,
    clearError: PropTypes.func,
    isLoading: PropTypes.bool,
  }).isRequired,
  children: PropTypes.node.isRequired,
};
