import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import styles from './PreSaveDialog.module.css';

export default function PreSaveDialog({ cvStatus, data, onSave, onSubmit, selectedCvId, ownCvId, isAdmin, onDismiss }) {
  if (selectedCvId && selectedCvId !== ownCvId && cvStatus === 'approved') {
    return <Dialog
      open={data.isOpened}
      keepMounted
      onClose={onDismiss}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{ style: { width: '500px' } }}
    >
      <div style={{ padding: '40px 65px 18px 65px' }}>
        <DialogTitle id="alert-dialog-slide-title" style={{ padding: 0 }}>
          <div style={{ fontWeight: "600" }}>
            Are you sure?
          </div>
        </DialogTitle>
        <div style={{ paddingBottom: "30px", paddingTop: '5px' }}>Please confirm that you want to approve CV</div>
        <DialogActions
          classes={{
            root: styles.popupControls,
          }}
        >
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={() => {
              onSubmit();
              onDismiss();
            }}
          >
            Approve
          </Button>
          <Button aria-controls="simple-menu" aria-haspopup="true" onClick={onDismiss}>
            Cancel
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  }

  if (selectedCvId && selectedCvId !== ownCvId && isAdmin) {
    return (
      <Dialog
        open={data.isOpened}
        keepMounted
        onClose={onDismiss}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div style={{ padding: '40px 65px 18px 65px' }}>
          <DialogTitle id="alert-dialog-slide-title" style={{ padding: 0 }}>
            <div style={{ fontWeight: "600" }}>
              Are you sure?
            </div>
          </DialogTitle>
          <div style={{ paddingBottom: "30px", paddingTop: '5px' }}>Do you want to submit changes and update your mentee’s CV?</div>
          <DialogActions
            classes={{
              root: styles.popupControls,
            }}
          >
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={() => {
                onSave();
                onDismiss();
              }}
            >
              Save
            </Button>
            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={onDismiss}>
              Cancel
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    );
  }

  return (
    <Dialog
      open={data.isOpened}
      keepMounted
      onClose={onDismiss}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <div style={{ padding: '40px 65px 18px 65px' }}>
        <DialogTitle id="alert-dialog-slide-title" style={{ padding: 0 }}>
          <div style={{ fontWeight: "600" }}>
            Are you sure?
          </div>
        </DialogTitle>
        <div style={{ paddingBottom: "30px", paddingTop: '5px' }}>Do you want to submit changes so that your TM will receive a notification or you want to continue editing?</div>
        <DialogActions
          classes={{
            root: styles.popupControls,
          }}
        >
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={() => {
              onSubmit();
              onDismiss();
            }}
          >
            Save and Send for Approval
          </Button>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={() => {
              onSave();
              onDismiss();
            }}
          >
            Save
          </Button>
          <Button aria-controls="simple-menu" aria-haspopup="true" onClick={onDismiss}>
            Cancel
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
}
