import React, { useState, useContext } from 'react';

import settingsIcon from '../../assets/images/icons/cv-settings.svg'
import summaryIcon from '../../assets/images/icons/summary.svg'

import AppContext from '../../context/AppContext';
import TabBar from '../../shared/TabBar';

import EducationTab from './tabs/Education';
import LanguagesTab from './tabs/Languages';
import SummaryTab from './tabs/Summary';
import ResponsibilitiesTab from './tabs/Responsibilities';
import ProgrammingLanguagesTab from './tabs/Programming-Languages';
import IdeOrToolsTab from './tabs/Ide-Tools';
import BugTrackingSystemsTab from './tabs/Development-Technology';
import VersionControlSystemsTab from './tabs/Version-Control-Systems';
import OtherTab from './tabs/Other';
import ProjectsTab from './tabs/Projects';
import SkillsTab from './tabs/Skills';
import DataBasesTab from './tabs/DataBases';
import CiCdToolsTab from './tabs/CiCdTools';
import IOsTechnologiesTab from './tabs/iOsTechnologies';
import AndroidTechnologiesTab from './tabs/AndroidTechnologies';
import DevelopmentMethodologiesTab from './tabs/DevelopmentMethodologies';
import CvSettingsTab from './tabs/CvSettings';

import { tabsExceptSummary } from '../../helpers/AppHelpers';
import { FeedbackMenu } from '../../shared/FeedbackMenu/FeedbackMenu';
import DomainsTab from './tabs/Domains';

const LeftSidebar = ({ showTab, setShowTab }) => {
  const context = useContext(AppContext);
  const { state, dispatch } = context;
  const { data } = state;
  const [anchorEl, setAnchorEl] = useState(null)
  const tabs = [
    {
      key: 'cvSettings',
      icon: settingsIcon,
      title: 'Cv Settings',
    },
    {
      key: 'summary',
      icon: summaryIcon,
      title: 'Summary',
      validation: data.summary.body.length > 0 && data.summary.body.length < 220,
    },
    ...tabsExceptSummary,
  ];
  const [currentTab, setCurrentTab] = useState(tabs[0].key);
  const onChange = (key, value, name) => {
    if (name === 'tasksExecuted') {
      value = value.substr(0, 500);
    }
    if (name === 'summary.description') {
      value = value.substr(0, 550);
    }
    dispatch({
      type: 'on_input',
      payload: {
        key,
        value,
      },
    });
  };

  const renderTabs = () => {
    if (!showTab) return null;

    switch (currentTab) {
      case 'cvSettings':
        return <CvSettingsTab onChange={onChange} />;
      case 'summary':
        return <SummaryTab data={data} onChange={onChange} dispatch={dispatch} />;
      case 'education':
        return <EducationTab data={data} onChange={onChange} />;
      case 'languages':
        return <LanguagesTab data={data} onChange={onChange} />;
      case 'skills':
        return <SkillsTab data={data} onChange={onChange} />;
      case 'domains':
        return <DomainsTab data={data} onChange={onChange} />;
      case 'programmingLanguages':
        return <ProgrammingLanguagesTab data={data} onChange={onChange} />;
      case 'dataBases':
        return <DataBasesTab data={data} onChange={onChange} />;
      case 'ideOrTools':
        return <IdeOrToolsTab data={data} onChange={onChange} />;
      case 'developmentTechnologies':
        return <BugTrackingSystemsTab data={data} onChange={onChange} />;
      case 'ciCdTools':
        return <CiCdToolsTab data={data} onChange={onChange} />;
      case 'versionControlSystems':
        return <VersionControlSystemsTab data={data} onChange={onChange} />;
      case 'iOsTechnologies':
        return <IOsTechnologiesTab data={data} onChange={onChange} />;
      case 'androidTechnologies':
        return <AndroidTechnologiesTab data={data} onChange={onChange} />;
      case 'developmentMethodologies':
        return <DevelopmentMethodologiesTab data={data} onChange={onChange} />;
      case 'other':
        return <OtherTab data={data} onChange={onChange} />;
      case 'experience':
        return <ResponsibilitiesTab data={data} onChange={onChange} />;
      case 'projects':
        return <ProjectsTab data={data} onChange={onChange} />;
      default:
        return null;
    }
  };

  const handleFeedBackPopup = (event) => {
    setAnchorEl(event.currentTarget)
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <FeedbackMenu anchorEl={anchorEl} handleClose={handleClose} />
      <div className="h-screen z-10 bg-white shadow-2xl overflow-y-scroll leftSidebar">
        <div id="leftSidebar" className={`py-6 ${showTab && 'grid grid-cols-7'}`}>
          <TabBar
            tabs={tabs}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            showTab={showTab}
            setShowTab={setShowTab}
            handleFeedBackPopup={handleFeedBackPopup}
          />
          <div id="tabs" className="px-6 w-full leftSidebar-tabs">
            {renderTabs()}
          </div>
        </div>
      </div>
    </>
  );
};

export default LeftSidebar;
