import { useTranslation } from 'react-i18next';
import React, { useContext } from 'react';
import { get, remove } from 'lodash';
import AppContext from '../../context/AppContext';
import { GetTechSkills } from '../../services/GetTechSkills';
import AutoComplete from '../AutoComplete';
import TextField from '../TextField';
import Dropdown from '../Dropdown';
import Checkbox from '../Checkbox';

const Form = ({ item, onChange, identifier = '', onTechSkillSelect, idTab, type, errors, setErrors }) => {
  const { t } = useTranslation('shared');

  const context = useContext(AppContext);
  const { state } = context;
  const { data } = state;

  const expLevel = [
    { key: 'Select level', value: '', disabled: true },
    { key: 'Basic', value: `${t('form.level.basic')}` },
    { key: 'Intermediate', value: `${t('form.level.intermediate')}` },
    { key: 'Advanced', value: `${t('form.level.advanced')}` },
    { key: 'Expert', value: `${t('form.level.expert')}` },
  ];

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open;
  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      let techSkills = await GetTechSkills(idTab);
      techSkills = techSkills.sort((a, b) => get(a, 'skill_title').localeCompare(get(b, 'skill_title')));
      techSkills = remove(techSkills, techSkill => !data[type].items.some(itemFromArray => {
        return get(techSkill, 'skill_title').toLowerCase() === get(itemFromArray, 'key').toLowerCase();
      }));
      if (active) {
        setOptions(techSkills);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading, data, idTab, type]);

  const defaultValue = options.find(project => get(project, 'skill_title') === item.key) || null;

  return (
    <div>
      {!item.isDefault && <AutoComplete
        label={t(`form.${type}.label`)}
        value={defaultValue}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        disabled={item.disabledAC}
        options={options}
        onChange={(v) => {
          if (onTechSkillSelect) {
            onTechSkillSelect(v);
          }
        }}
        placeholder={t(`form.${type}.placeholder`)}
        getOptionLabel={(option) => get(option, 'skill_title')}
        tooltip={'right'}
        tooltipType={type}
        tooltipLabel={'autoComplete'}
      />}

      {!item.isDefault && <div className="event_line">
        <span className="event__hide_label">{t('form.line.label')}</span>
      </div>}

      {!item.isDefault && <TextField
        className="mb-6"
        classNameInput={item.disabled}
        label={t('form.fieldName.label')}
        placeholder={t('form.fieldName.placeholder')}
        value={item.key}
        disabled={item.disabled}
        star="true"
        validation={errors.key}
        onChange={v => {
          onChange(`${identifier}key`, v);
          setErrors({
            key: false,
            years: errors.years,
          });
        }}
        tooltip={'right'}
        tooltipType={type}
        tooltipLabel={t('form.fieldName.label')}
      />}

      <div className="mb-6">
        <TextField
          className="mb-1"
          label={t('form.fieldYears.label')}
          placeholder={t('form.fieldYears.placeholder')}
          value={item.years}
          star="true"
          validation={errors.years}
          onChange={v => {
            if (item.isDefault) {
              item.wasChanged = true;
            }
            onChange(`${identifier}years`, v);
            setErrors({
              key: errors.key,
              years: false,
            });
          }}
          tooltip={'right'}
          tooltipType={type}
          tooltipLabel={t('form.fieldYears.label')}
        />
        <Checkbox
          checked={item.notActive}
          onChange={v => onChange(`${identifier}notActive`, v)}
          size="1rem"
          label={t('form.checkBox.label')}
        />
      </div>

      <Dropdown
        label={t('form.level.label')}
        value={item.level}
        className="mb-6"
        options={expLevel}
        onChange={v => {
          if (item.isDefault) {
            item.wasChanged = true;
          }
          onChange(`${identifier}level`, v);
        }}
        optionItem={x => (
          <option key={x.key} value={x.value} selected={x.disabled} disabled={x.disabled}>
            {x.disabled ? "Select level" : x.value}
          </option>
        )}
        tooltip={'right'}
        tooltipType={type}
        tooltipLabel={t('form.level.label')}
      />
    </div>
  );
};

export default Form;
