import React, { useRef } from 'react';
import { AutoComplete, Space } from 'antd';
import { searchParamList } from '../../../../../helpers/AppHelpers';

const UserNameFilter = (props) => {
  const userNameRef = useRef(null);
  const { searchItems, usernames, setUser, setUserNames } = props;
  const handleUserKeyDown = (event) => {
    if (event.key === 'Enter') {
      setUser({ searchParam: 'user', value: event.target.value });
      userNameRef.current.blur();
    }
  };

  return (
    <>
      {searchItems.includes(searchParamList[0].value) && (
        <Space size="small" direction="vertical" className="w-full">
          <label>{searchParamList[0].label}</label>
          <AutoComplete
            ref={userNameRef}
            dataSource={usernames}
            filterOption={usernames}
            allowClear
            style={{ width: '100%' }}
            placeholder={searchParamList[0].placeholder}
            onSelect={(v) => {
              setUser({ searchParam: 'user', value: v });
              userNameRef.current.blur();
            }}
            onKeyDown={handleUserKeyDown}
            onClear={() => {
              setUser({});
              setUserNames([])
            }}
            type="text"
          />
        </Space>
      )}
    </>
  );
};

export default UserNameFilter;
