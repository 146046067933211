import React from 'react';
import { Select, Space } from 'antd';
import { searchParamList, langLevels, switchLanguageName } from '../../../../../helpers/AppHelpers';
import EnglishLevels from '../../../../../assets/images/english-levels-new.png'
import { ImageTooltip } from '../../../../../shared/Tooltips/ImageTooltip';

const EnglishFilter = (props) => {
  const { searchItems, setEnglishLevel } = props;
  const reverseChangeLanguageName = (value) => {
    switch (value) {
      case 'A1 (Elementary)':
        return 'Elementary';
      case 'A2 (Pre Intermediate) ':
        return 'Pre-Intermediate';
      case 'A2+ (Pre Intermediate+)':
        return 'A2+ (Pre Intermediate+)';
      case 'B1 (Intermediate)':
        return 'Intermediate';
      case 'B1+ (Intermediate+)':
        return 'B1+ (Intermediate+)';
      case 'B2 (Upper Intermediate)':
        return 'Upper-Intermediate';
      case 'B2+ (Upper Intermediate+)':
        return 'B2+ (Upper Intermediate+)';
      case 'C1 (Advanced)':
        return 'Advanced';
      case 'C2 (Proficiency)':
        return 'C2 (Proficiency)';
      case 'Native':
        return 'Native';
      default:
        return value;
    }
  };
  const handleEnglishChange = (item) => {
    let updatedItem = reverseChangeLanguageName(item);
    setEnglishLevel({ searchParam: 'english', value: updatedItem });
  };
  const allLangLevels = langLevels.slice(1);
  const updatedLangLevels = allLangLevels.map(lang => ({
    ...lang,
    value: switchLanguageName(lang.value)
  }));

  return (
    <>
      {searchItems.includes(searchParamList[5].value) ? (
        <Space size="small" direction="vertical" className="w-full">
          <div className='flex flex-row gap-2 items-center'>
            <label>{searchParamList[5].label}</label>
            <ImageTooltip placement={'left'} imgSrc={EnglishLevels} />
          </div>
          <Select
            className="w-full"
            allowClear
            placeholder={searchParamList[5].placeholder}
            options={updatedLangLevels}
            onChange={handleEnglishChange}
          />
        </Space>
      ) : null}
    </>
  );
};

export default EnglishFilter;
