import React, { useState } from 'react'
import { Collapse, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { get } from 'lodash'
import { ReactComponent as ExpandLess } from '../../../assets/images/expand-less.svg'
import { ReactComponent as ExpandMore } from '../../../assets/images/expand-more.svg'
import { ReactComponent as GoogleDocIcon } from '../../../assets/images/menu-icons/google-docs.svg'

export const GoogleDocItems = ({ templates, sendData, handleClose }) => {
  const [openDoc, setOpenDoc] = useState(false)

  const handleClickDoc = () => {
    setOpenDoc(!openDoc)
  }

  return (
    <>
      <ListItem button onClick={handleClickDoc} style={{ gap: '8px', padding: '4px 10px', borderBottom: !openDoc && 'none' }}>
        <ListItemIcon style={{ minWidth: 0 }}>
          <GoogleDocIcon />
        </ListItemIcon>
        <ListItemText primary="Export as Google Doc" />
        {openDoc ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openDoc} timeout="auto" unmountOnExit style={{ borderBottom: openDoc && 'none' }} >
        <List component="div" disablePadding>
          {templates.map(template => {
            return <ListItem key={get(template, 'id')} button onClick={() => { sendData(get(template, 'url'), get(template, 'name')); handleClose() }} style={{ paddingTop: 4, paddingBottom: 4, paddingLeft: 12 }}>
              <ListItemText primary={get(template, 'name')} />
            </ListItem>
          })}
        </List>
      </Collapse>
    </>
  )
}
