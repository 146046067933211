import React, { forwardRef, useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';
import { get } from 'lodash';
import CopyIcon from '../../assets/images/icons/copy.png';
import styles from './TempLinkPopup.module.css';
import TextField from '../TextField';

import PostTempLinkRequest from '../../services/PostTempLinkRequest';
import { infoToast } from '../toast';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export const TempLinkPopup = ({ open, setOpen, userID, resumeId }) => {
  const [link, setLink] = useState("");

  const onClose = () => {
    setOpen(false);
    setLink("");
  }

  const data = { user: userID, resumeId: resumeId };

  const copyLink = () => {
    navigator.clipboard.writeText(link);
    if (link) {
      infoToast(
        'Link was copied to the clipboard',
      );
    }
  }
  useEffect(() => {
    if (open && link === "") PostTempLinkRequest(data).then(res => setLink(get(res, 'link')));
  });

  return (
    <Dialog
      open={open}
      classes={{
        root: styles.popupRoot,
        paper: styles.popupContainer,
      }}
      BackdropProps={{
        classes: {
          root: styles.backDrop,
        },
      }}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description">
      <DialogTitle
        classes={{
          root: styles.popupTitle,
        }}
        id="alert-dialog-slide-title">
        Here is a temporary link for the selected CV
      </DialogTitle>
      <DialogContent
        classes={{
          root: styles.popupText,
        }}
      >
        <div className='flex mb-4 justify-center'>It expires in 7 days</div>
        <div className='flex items-center'>
          <TextField
            className="mr-3"
            value={link}
          />
          <Button>
            <div onClick={copyLink} style={{ width: '25px', cursor: 'pointer' }}>
              <img src={CopyIcon} alt='Copy link' />
            </div>
          </Button>
        </div>
      </DialogContent>
      <DialogActions
        classes={{
          root: styles.popupControls,
        }}
      >
        <Button onClick={onClose}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
