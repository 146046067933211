/* eslint-disable import/prefer-default-export */

const resources = {
  EMPLOYEES_DESCRIPTION: '/employees/description',
  PROJECT_RESPONSIBILITIES: '/projects/responsibilities',
  PROJECT_DESCRIPTION: '/projects/description',
};

function createRequestOptions(body = {}) {
  return {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  };
}

function postRequest(resource, params) {
  const requestOptions = createRequestOptions(params);
  const urlParams = new URLSearchParams({
    key: process.env.REACT_APP_BEAUTIFIER_API_KEY,
  });
  const url = `${process.env.REACT_APP_BEAUTIFIER_API_URL}${resource}?${urlParams}`;
  return fetch(url, requestOptions).then((response) => response.json());
}

/**
 *
 * @param {object} description
 * @returns {Promise}
 */
export function beautifyEmployeeDescription({ name, level, position, years, description }) {
  return postRequest(resources.EMPLOYEES_DESCRIPTION, {
    name,
    level,
    position,
    years,
    description,
  });
}

/**
 *
 * @param {string} responsibilities
 * @returns {Promise}
 */
export function beautifyProjectResponsibilities(responsibilities) {
  return postRequest(resources.PROJECT_RESPONSIBILITIES, { responsibilities });
}

/**
 *
 * @param {string} description
 * @returns {Promise}
 */
export function beautifyProjectDescription(description) {
  return postRequest(resources.PROJECT_DESCRIPTION, { description });
}
