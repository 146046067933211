import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

export const FeedbackMenu = ({ anchorEl, handleClose }) => {
  return (
    <Menu
      id="feedback-menu"
      getContentAnchorEl={null}
      style={{ left: '60px' }}
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={handleClose}
    >
      <MenuItem
        onClick={() => {
          window.open('https://help.softteco-tools.com/');
        }}
      >
        Contact support
      </MenuItem>
      <MenuItem
        onClick={() => {
          window.open(
            'https://docs.google.com/document/d/1RXzGAUtbjjmQyGGCiNdiq63pY6BNl8tiRMEwaDELmas/edit#heading=h.50ofwtuhd3kp',
          );
        }}
      >
        How to report an issue/bug
      </MenuItem>
    </Menu>
  );
};
