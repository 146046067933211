import React from 'react';
import { useTranslation } from 'react-i18next';
import TextField from '../TextField';
import Dropdown from '../Dropdown';
import { langLevels, switchLanguageName } from '../../helpers/AppHelpers';

const Form = ({ item, onChange, identifier = '', errors, setErrors }) => {
  const { t } = useTranslation('leftSidebar');

  return (
    <div>
      <TextField
        className="mb-6"
        label={t('languages.key.label')}
        placeholder={t('languages.key.placeholder')}
        value={item.key}
        star="true"
        validation={errors.key}
        onChange={v => {
          onChange(`${identifier}key`, v);
          setErrors({
            key: false,
          });
        }}
      />

      <Dropdown
        label={t('languages.level.label')}
        imageTooltip={'right'}
        className="mb-6"
        value={item.level}
        options={langLevels}
        onChange={v => onChange(`${identifier}level`, v)}
        optionItem={x => {
          return <option key={x.key} value={x.value} selected={x.disabled} disabled={x.disabled}>
            {x.disabled ? "Select Level" : switchLanguageName(x.value)}
          </option>
        }
        }
      />
    </div>
  );
};

export default Form;
