import React, { useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { BurgerList } from './BurgerList';
import burgerIcon from '../../assets/images/burger.svg';


export const BurgerMenu = ({  setOpenLinkModal, spinner, templates, sendData, data }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMainClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title="Open menu">
        <div onClick={handleMainClick} className="w-10 h-10 ml-1 rounded-full hover:bg-gray-200 cursor-pointer flex items-center justify-center">
          <img src={burgerIcon} className="w-6" alt="burger" />
        </div>
      </Tooltip>
      <BurgerList templates={templates}  setOpenLinkModal={setOpenLinkModal} data={data} spinner={spinner} anchorEl={anchorEl} handleClose={handleClose} sendData={sendData} />
    </>
  );
}