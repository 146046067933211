import React, { useState } from 'react';
import ItemHeadingWithDeleteButton from './ItemHeadingWithDeleteButton';
import Form from './Form';

const Item = ({ item, index, isDisabled, deleteItem, copyItem, setItemAsMain, onChange, activeResume, isSale}) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <div className="my-4 border border-gray-200 rounded">
      <ItemHeadingWithDeleteButton
        setOpen={setOpen}
        isOpen={isOpen}
        item={item}
        deleteItem={deleteItem}
        onChange={onChange}
        activeResume={activeResume}
        isSale={isSale}
      />

      <div className={`${isOpen ? 'block' : 'hidden'} px-5 pt-3 pb-5`}>
        <Form
          item={item}
          index={index}
          isDisabled = {isDisabled}
          copyItem={copyItem}
          setItemAsMain={setItemAsMain}
          onChange={onChange}
          activeResume={activeResume}
          isSale={isSale}
        />
      </div>
    </div>
  );
};

export default Item;
