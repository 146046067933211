import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import styles from './Dialog.module.css';

export default function ChangesDialog({ data, setDialogData }) {
  return (
    <Dialog
      open={data.isOpened}
      keepMounted
      onClose={()=> setDialogData({ isOpened:false })}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
      <div className="flex flex-col justify-center items-center">
        <p>You have unsaved changes</p>
        <p>Please, choose to Save or Cancel</p>
      </div>
      </DialogTitle>
      <DialogActions
        classes={{
          root: styles.popupControls,
        }}
      >
        <Button aria-controls="simple-menu" aria-haspopup="true" onClick={()=> setDialogData({ isOpened:false })}>
          Back
        </Button>
      </DialogActions>
    </Dialog>
  );
}
