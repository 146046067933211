import React, { useState, useContext } from 'react';
import TextField from '../TextField';
import Checkbox from '../Checkbox';
import AppContext from '../../context/AppContext';
import SetAsMainDialog from './SetAsMainDialog';

const Form = ({ item, index, isDisabled, copyItem, setItemAsMain, onChange, activeResume, isSale }) => {
  const context = useContext(AppContext);
  const { state } = context;
  const { resumeItems, dataChanged } = state;
  const [dialogMainData, setDialogMainData] = useState({ isOpened: false });

  const onAgree = () => {
    setItemAsMain(item.id);
    setDialogMainData({ isOpened: false });
  };

  const onDismiss = () => {
    setDialogMainData({ isOpened: false });
  };

  return (
    <div>
      <SetAsMainDialog data={dialogMainData} onAgree={onAgree} onDismiss={onDismiss} dataChanged={dataChanged} />
      <TextField
        className={`${item.id !== activeResume ? "text-whight" : ""} mb-6`}
        label="Name"
        placeholder="Name"
        value={resumeItems[index].name}
        star="true"
        onChange={(value) => {
          resumeItems[index].name = value;
          onChange(`data.name`, value);
        }}
        disabled={item.id !== activeResume || isSale}
      />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {!isSale && <Checkbox
          label="Set as main"
          checked={item.isMain}
          onChange={() => {
            if (item.isMain === false)
              setDialogMainData({ isOpened: true });
          }}
        />}
        {!isSale && <button
          type="button"
          disabled={isDisabled}
          onClick={() => copyItem(item.id, item.name)}
          className={`${isDisabled
              ? 'bg-gray-300 cursor-default text-white'
              : 'bg-gray-600 hover:bg-gray-700 cursor-pointer'
            }  text-white text-sm font-medium py-2 px-5 rounded`}
        >
          Duplicate CV
        </button>}
      </div>
    </div>
  );
};

export default Form;
