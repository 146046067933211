import React, { useContext, useState } from 'react'
import { Collapse, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { ReactComponent as ExpandLess } from '../../../assets/images/expand-less.svg'
import { ReactComponent as ExpandMore } from '../../../assets/images/expand-more.svg'
import { ReactComponent as PdfIcon } from '../../../assets/images/menu-icons/icon-pdf.svg'
import PageContext from '../../../context/PageContext'
import { saveAsMultiPagePdf, saveAsMultiPageRusPdf } from '../../../utils'

export const PdfItems = ({ spinner, handleClose, data }) => {
  const [openPdf, setOpenPdf] = useState(false)
  const pageContext = useContext(PageContext);
  const { pageRef, panZoomRef } = pageContext;

  const handlePdf = async () => {
    spinner.start()
    saveAsMultiPagePdf(pageRef, panZoomRef, data?.summary?.firstName, data?.summary?.lastName, data?.summary?.position).then(spinner.stop)
    handleClose()
  }

  const handleRusPdf = async () => {
    spinner.start()
    saveAsMultiPageRusPdf(pageRef, data?.summary?.firstName, data?.summary?.lastName, data?.summary?.position).then(spinner.stop)
    handleClose()
  }

  const handleClickPdf = () => {
    setOpenPdf(!openPdf)
  }

  return (
    <>
      <ListItem button onClick={handleClickPdf} style={{ gap: '8px', padding: '4px 10px', borderBottom: !openPdf && '1px solid #EDF2F7' }}>
        <ListItemIcon style={{ minWidth: 0 }}>
          <PdfIcon />
        </ListItemIcon>
        <ListItemText primary="Export as PDF" />
        {openPdf ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={openPdf} timeout="auto" unmountOnExit style={{ borderBottom: openPdf && '1px solid #EDF2F7' }} >
        <List component="div" disablePadding>
          <ListItem button style={{ paddingTop: 4, paddingBottom: 4, paddingLeft: 12 }} onClick={handlePdf} >
            <ListItemText primary="Softteco EN" />
          </ListItem>
          <ListItem button style={{ paddingTop: 4, paddingBottom: 4, paddingLeft: 12 }} onClick={handleRusPdf}>
            <ListItemText primary="Softteco RU" />
          </ListItem>
        </List>
      </Collapse>
    </>
  )
}
