import React from 'react';
import { Select, Space } from 'antd';
import { searchParamList } from '../../../../../helpers/AppHelpers';

const ContractTypeFilter = (props) => {
  const { searchItems, contractTypes, setContractType } = props;
  const handleisContractTypesChange = (item) => {
    setContractType({ searchParam: 'contractType', value: item });
  };

  return (
    <>
      {searchItems.includes(searchParamList[7].value) ? (
        <Space size="small" direction="vertical" className="w-full">
          <label>{searchParamList[7].label}</label>
          <Select
            className="w-full"
            allowClear
            placeholder={searchParamList[7].placeholder}
            options={contractTypes}
            onChange={handleisContractTypesChange}
          />
        </Space>
      ) : null}
    </>
  );
};

export default ContractTypeFilter;
