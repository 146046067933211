import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { moveItemBy } from '../utils';

export default function DragNDropList({ type, dispatch, data, children }) {
  const dataTabItems = data[type].items;

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    moveItemBy(
      dispatch,
      type,
      dataTabItems[result.source.index],
      result.destination.index - result.source.index,
    );
  }

  return (children &&
    <div>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="characters">
          {(provided) => (
            <div className="characters" {...provided.droppableProps} ref={provided.innerRef}>
              {children.map((x, index) => {
                return (
                  <Draggable
                    key={dataTabItems[index].id}
                    draggableId={dataTabItems[index].id}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {children[index]}
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}
