import React from 'react'
import { Tooltip } from 'antd'
import AlertIcon from '../../assets/images/alert-circle.png'
import { ReactComponent as SofttecoLogo } from '../../assets/images/softteco-circle.svg'

const ImageContent = ({ imgSrc }) => {
  return (
    <div className='p-2'>
      <p className="text-black font-semibold text-2xl">
        Language Level System
      </p>
      <div className="flex-row gap-1 flex pb-8">
        <p className="text-black">
          International standard /
        </p>
        <a className="font-semibold" target='_blank' rel="noopener noreferrer" href='https://docs.google.com/document/d/1_X2WQBtKobmFyMH0f0ZQWCaB_xGH_vF-CrrWqtHeCPc/edit'>
          <div className="flex flex-row gap-1 items-center">
            <SofttecoLogo />
            <p className='text-blue-900 font-semibold'>SoftTeco Internal Scale</p>
          </div>
        </a>
      </div>
      <img src={imgSrc} alt='help' />
    </div>
  )
}

export const ImageTooltip = ({ placement, imgSrc }) => {
  return (
    <div>
      <Tooltip placement={placement || 'bottom'} title={<ImageContent imgSrc={imgSrc} />} color='#FFF' overlayStyle={{ maxWidth: '520px' }}>
        <div className='w-5 h-5'>
          <img src={AlertIcon} alt='i' />
        </div>
      </Tooltip>
    </div>
  )
}
