import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from '../../../context/AppContext';
import Checkbox from '../../../shared/Checkbox';
import AddItem from '../../../shared/Certifications/AddItem';
import DragNDropList from '../../../shared/DragNDropList';
import Item from '../../../shared/Certifications/Item';
import { TabDescription } from '../../../shared/TabDescription/TabDescription';

const Certifications = ({ data, onChange }) => {
  const context = useContext(AppContext);
  const { dispatch } = context;
  const { t } = useTranslation('leftSidebar');

  return (
    <>
      <hr className="my-2" />
      <div className="mb23 flex justify-between items-center">
        <div className="title_tab col-span-5 mt-4">{t('certifications.title.label')}</div>
        <div className="col-span-1">
          <Checkbox
            checked={data.certifications.enable}
            onChange={v => onChange('data.certifications.enable', v)}
          />
        </div>
      </div>
      <TabDescription tab={'certifications'} />
      <DragNDropList
        data={data}
        dispatch={dispatch}
        onChange={onChange}
        type="certifications"
      >
        {data.certifications.items.map((x, index) => (
          <Item
            item={x}
            key={x.id}
            index={index}
            onChange={onChange}
            dispatch={dispatch}
            first={index === 0}
            last={index === data.certifications.items.length - 1}
          />
        ))}
      </DragNDropList>
      <AddItem heading={"Certificate"} dispatch={dispatch} />
    </>
  )
}

export default Certifications