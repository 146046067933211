import config from '../config';
import { getElementFromStorage } from '../utils/storage';

export const SetResumeItemAsMain = (uId, rId) => {
  const baseURL = `${config.http.BASE_URL}/resume-items/setAsMain`;
  const token = getElementFromStorage('jwtToken');

  const reqHeaders = new Headers();
  reqHeaders.append('Content-Type', 'application/json');
  reqHeaders.append('Authorization', `Bearer ${token === 'undefined' ? '' : token}`);

  const raw = JSON.stringify({
    userId: uId,
    resumeId: rId,
  });

  const requestOptions = {
    method: 'POST',
    headers: reqHeaders,
    body: raw,
  };

  return fetch(baseURL, requestOptions).then(
    (response) =>
      response.status !== 200
        ? {
          error: true,
        }
        : response
          .json()
          .then((json) => json)
          .catch(() => ({
            error: true,
          })),
    (error) => {
      throw new Error(error.message);
    },
  );
};

export default SetResumeItemAsMain;
