import React, { useRef } from 'react';
import { AutoComplete, Space } from 'antd';
import { searchParamList } from '../../../../../helpers/AppHelpers';

const ProjectFilter = (props) => {
  const { searchItems, projects, setProject } = props;
  const projectsRef = useRef(null);
  const handleProjectKeyDown = (event) => {
    if (event.key === 'Enter') {
      setProject({ searchParam: 'projects', value: event.target.value });
      projectsRef.current.blur();
    }
  };

  return (
    <>
      {searchItems.includes(searchParamList[3].value) ? (
        <Space size="small" direction="vertical" className="w-full">
          <label>{searchParamList[3].label}</label>
          <AutoComplete
            ref={projectsRef}
            dataSource={projects}
            filterOption={projects}
            onSelect={(v) => {
              setProject({ searchParam: 'projects', value: v });
              projectsRef.current.blur();
            }}
            allowClear
            type="text"
            style={{ width: '100%' }}
            placeholder={searchParamList[3].placeholder}
            onKeyDown={handleProjectKeyDown}
            onClear={(projectValue) => {
              setProject({ searchParam: 'projects', value: projectValue });
              projectsRef.current.blur();
            }}
          />
        </Space>
      ) : null}
    </>
  );
};

export default ProjectFilter;
