import { Menu, styled } from "@material-ui/core";

export const ToolsPopup = styled(Menu)({
  "& .MuiList-padding.MuiMenu-list": {
    borderRadius: "80px",
  },
  "& .MuiMenuItem-gutters": {
    borderRadius: "4px",
    margin: "4px 6px 4px 6px",
    padding: "0 18px 0 6px",
  },
  "& .MuiPaper-rounded": {
    width: "332px",
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
});

export const ToolsIFrame = styled('iframe')({
  width: "332px",
  height: "474px",
  border: 'none',
})