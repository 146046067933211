import profile from './profile.json';
import objective from './objective.json';
import work from './work.json';
import education from './education.json';
import awards from './awards.json';
import certifications from './certifications.json';
import languages from './languages.json';
import references from './references.json';
import extras from './extras.json';

import cvSettings from './cvSettings.json';
import summary from './summary.json';
import technicalSkills from './technical-skills.json';
import experience from './experience.json';
import programmingLanguages from './programmingLanguages.json';
import ideOrTools from './ideOrTools.json';
import developmentTechnologies from './developmentTechnologies.json';
import versionControlSystems from './versionControlSystems.json';
import other from './other-tech-skills.json';
import projects from './projects.json';
import skills from './skills.json';
import dataBases from './dataBases.json';
import ciCdTools from './ciCdTools.json';
import iOsTechnologies from './iOsTechologies.json';
import androidTechnologies from './androidTechologies.json';
import developmentMethodologies from './developmentMethodologies.json';
import keySkills from './keySkills.json';
import domains from './domains.json';

export default {
  profile,
  objective,
  work,
  education,
  awards,
  certifications,
  languages,
  references,
  extras,
  summary,
  technicalSkills,
  experience,
  programmingLanguages,
  ideOrTools,
  developmentTechnologies,
  versionControlSystems,
  other,
  projects,
  skills,
  dataBases,
  ciCdTools,
  iOsTechnologies,
  androidTechnologies,
  developmentMethodologies,
  keySkills,
  domains,
  cvSettings
};
