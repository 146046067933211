import React from 'react';
import { Select } from 'antd';

const TagSelect = ({ onChange, placeholder }) => (
  <Select
    mode="tags"
    allowClear
    style={{ width: '100%' }}
    placeholder={placeholder}
    onChange={onChange}
    suffixIcon={null}
    open={false}
  />
);

export default TagSelect;
